<script>
import {onMounted, ref, watch} from "vue";
import { useI18n } from "vue-i18n";
import CharityCard from "@/javascript/components/charity-card.vue";

// Stores
import { useChaptersStore } from '@/javascript/stores/chapters.js';
import { useVotingEventsStore } from '@/javascript/stores/voting_events.js';
import { useMembersStore } from '@/javascript/stores/members.js';
import { useAuthenticationStore } from '@/javascript/stores/authentication.js';
import {debounce} from "lodash";

export default {
  components: { CharityCard },
  setup() {
    // Stores
    const chaptersStore = useChaptersStore();
    const votingEventsStore = useVotingEventsStore();
    const membersStore = useMembersStore();
    const authenticationStore = useAuthenticationStore();

    // I18n
    const { t } = useI18n();

    // Variables
    const isLoading = ref(true);
    const chapterSearch = ref("");
    const searchedChapters = ref([]);

    onMounted(async () => {
      await authenticationStore.loadUserData(document.body.dataset.currentUserId);

      await getDashboardData();
    });

    const getDashboardData = async () => {
      let chapterParams = new URLSearchParams();
      chapterParams.set('query[s]', 'name ASC');
      chapterParams.set('query[archived_eq]', 'false');

      let memberParams = new URLSearchParams();
      memberParams.set('query[user_id_eq]', document.body.dataset.currentUserId);
      memberParams.set('query[archived_eq]', 'false');

      const estDate = new Date(new Date().getTime());
      let votingEventParams = new URLSearchParams();
      votingEventParams.set('query[end_time_gteq]', estDate);
      votingEventParams.set('query[s]', 'start_time ASC');
      votingEventParams.set('query[archived_eq]', 'false');

      try {
        await Promise.all([
          chaptersStore.getChapters(chapterParams),
          membersStore.getMembers(memberParams),
          votingEventsStore.getVotingEvents(votingEventParams)
        ]);

        isLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    };

    const getMemberForChapter = (chapterId) => {
      return membersStore.getMemberByChapterId(chapterId, document.body.dataset.currentUserId);
    };

    const getVotingEventForChapter = (chapterId) => {
      return votingEventsStore.getVotingEventByChapterId(chapterId);
    };

    const createMembership = async (chapter) => {
      isLoading.value = true;
      searchedChapters.value = [];
      chapterSearch.value = "";

      new Promise((resolve, reject) => {
        let data = {
          memberable_type: "Chapter",
          memberable_id: chapter.id,
          user_id: document.body.dataset.currentUserId
        };

        membersStore.newMember(data)
          .then(async () => {
            resolve();
            await getDashboardData();
          })
          .catch(error => {
            isLoading.value = false;
            reject(error);
          });
      });
    };

    const getChaptersByName = (search) => {
      let chapterParams = new URLSearchParams();
      chapterParams.set('query[s]', 'name ASC');
      chapterParams.set('query[archived_eq]', 'false');
      chapterParams.set('query[name_cont]', search);

      chaptersStore.searchChapters(chapterParams)
        .then((chapters) => {
          searchedChapters.value = chapters;
        });
    };

    const debouncedSearch = debounce((search) => {
      if (search) {
        getChaptersByName(search);
      } else {
        searchedChapters.value = [];
      }
    }, 250);

    watch(chapterSearch, debouncedSearch, { immediate: false });

    return {
      chaptersStore,
      votingEventsStore,
      membersStore,
      isLoading,
      t,
      chapterSearch,
      createMembership,
      searchedChapters,
      getMemberForChapter,
      getVotingEventForChapter,
    };
  }
};
</script>

<template>
  <div class="flex flex-col items-center">
    <div>
      <h2 v-if="membersStore.members && membersStore.members.length > 0" class="title-h2 text-primary-color mb-2">
        {{ t('dashboard.chapters_belong_to') }}
      </h2>
      <h3 v-else class="title-h3 text-primary-color mb-2">
        {{ t('dashboard.welcome') }}
      </h3>
      <div v-if="!isLoading" class="justify-center">
        <div class="block mb-15 rounded-lg p-3 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-70 search-border">
          <h2 class="text-md font-semibold">{{ t('dashboard.search_chapters') }}</h2>

          <div class="flex">
            <input
              v-model="chapterSearch"
              type="text"
              :placeholder="`${t('dashboard.search_chapter_placeholder')}...`"
              class="mt-2 w-full px-4 py-2 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
            >
          </div>

          <div v-for="chapter in searchedChapters" :key="chapter.id" class="block mb-10 p-3 rounded-lg shadow-sm dark:bg-neutral-700 bg-white flex items-center justify-between">
            <p class="text-lg font-medium">{{ chapter.name }}</p>
            <button type="button" class="primary-button" @click="createMembership(chapter)">
              {{ t('dashboard.join') }}
            </button>
          </div>
        </div>
        <CharityCard
          v-for="(chapter, index) in chaptersStore.chapters"
          :key="index"
          :chapter="chapter"
          :member="getMemberForChapter(chapter.id)"
          :voting-event="getVotingEventForChapter(chapter.id)"
          :is-loading="isLoading"
          class="w-full sm:w-auto"
        />
      </div>
      <div v-else>
        <div class="fixed inset-0 flex items-center justify-center">
          <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
