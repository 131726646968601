// Vue Imports
import { createApp } from 'vue';

// Pinia
import { createPinia } from 'pinia';

// App
import App from "@/javascript/views/App.vue";

// Router
import router from '@/javascript/router/router';
import '@/entrypoints/application.scss';

// I18n
import { createI18n } from 'vue-i18n';
import { en as usaEnglish } from '@/javascript/i18n/usa_english';
// import { cen as canEnglish } from '@/javascript/i18n/canadian_english';
// import { fr as canFrench } from '@/javascript/i18n/canadian_french';

// Mount App
const mountApp = (locale) => {
  const app = createApp(App);
  app.config.devtools = true;
  const pinia = createPinia();
  const i18n = createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'en',
    messages: {
      en: usaEnglish,
      // cen: canEnglish,
      // fr: canFrench,
    },
  });

  app
    .use(pinia)
    .use(i18n)
    .use(router);

  app.mount('#app');
};

mountApp('en');
