import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, createApi, updateApi, getApi } from "@/javascript/lib/api.service.js";

export const useVotingEventsStore = defineStore('votingEvents', () => {
  const votingEvents = ref(null);
  const lastParams = ref(null);

  const getVotingEvents = async (params = null, override = false) => {
      try {
        if (!override && (params === null || params === lastParams.value) && votingEvents.value) {
          return votingEvents.value;
        }
        let url = '/voting_events';
        votingEvents.value = await fetchData(url, params);
        lastParams.value = params;
        return votingEvents.value;
      } catch (e) {
        console.error(e);
        return null;
      }
    };

  const createVotingEvent = async (data = null) => {
    try {
      let url = `/voting_events`;
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const updateVotingEvent = async (data = null) => {
    try {
      let url = `/voting_events/${data.id}`;
      const response = await updateApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getVotingEventTeamVote = async (id = null, data = null) => {
    try {
      let url = `/voting_events/${id}/team_vote`;
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getVotingEventByChapterId = (chapterId) => {
    return votingEvents.value.find((votingEvent) => votingEvent.chapter_id === chapterId);
  };

  return {
    votingEvents,
    getVotingEvents,
    createVotingEvent,
    updateVotingEvent,
    getVotingEventTeamVote,
    getVotingEventByChapterId,
  };
});
