const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
  'X-Requested-With': 'XMLHttpRequest',
};

function genericApi(baseUrl, params, method, newRecord = false) {
  if (method === 'POST' || method === 'PATCH') {
    return fetch(baseUrl, {
      headers: DEFAULT_HEADERS,
      body: JSON.stringify(params),
      method,
    }).then((response) => response.json().then((json) => ({
      status: response.status,
      json,
    })));
  }

  if (method === 'UPLOAD') {
    return fetch(baseUrl, {
      headers: {
        Accept: 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.content,
      },
      body: params,
      method: newRecord ? "POST" : "PATCH"
    }).then((response) => response.json().then((json) => ({
      status: response.status,
      json,
    })));
  }

  let init = {
    headers: DEFAULT_HEADERS,
    method: method,
  };

  if (method !== 'DELETE') {
    const finalUrl = (params) ? `${baseUrl}?${params.toString()}` : `${baseUrl}`;
    return fetch(finalUrl, init).then((response) => response.json().then((json) => ({
      status: response.status,
      json,
    })));
  } else {
    const finalUrl = (params) ? `${baseUrl}?${params.toString()}` : `${baseUrl}`;
    return fetch(finalUrl, init).then((response) => {
      if (response.status === 204) {
        // Refresh browser so users sees login page
        window.location.reload();
      }
    });
  }
}

export function createApi(url, data) {
  return genericApi(url, data, 'POST');
}

export function updateApi(url, data) {
  return genericApi(url, data, 'PATCH');
}

export function getApi(url, params) {
  return genericApi(url, params, 'GET');
}

export function getSearchApi(url, params) {
  return genericApi(url, params, 'GET');
}

export function deleteApi(url, params) {
  return genericApi(url, params, 'DELETE');
}

export function uploadApi(url, params, newRecord = false) {
  return genericApi(url, params, 'UPLOAD', newRecord);
}

export async function fetchData(url, params = null) {
  let data = [];
  let currentPage = 1;
  let totalCount = 0;

  do {
    // Fetch data for the current page
    const response = await getApi(url + `?page=${currentPage}` + (params ? '&' + params.toString() : ''));
    const responseData = response.json;

    if (responseData.data.length > 0) {
      data.push(...responseData.data);
      totalCount = responseData.meta.count;
      currentPage++;
    } else {
      break; // No more data available, exit loop
    }
  } while (data.length < totalCount); // Continue fetching until total count is reached

  return data;
}
