<script>
import {onMounted, ref, watch} from "vue";
import { useI18n } from "vue-i18n";

// Stores
import { useCharitiesStore } from '@/javascript/stores/charities.js';
import { useModalStore } from '@/javascript/stores/modal.js';
import { debounce } from "lodash";

export default {
  props: {
    chapter: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    // Stores
    const charitiesStore = useCharitiesStore();
    const modalStore = useModalStore();

    // Variables
    const searchTerm = ref("");
    const isLoading = ref(true);

    onMounted(() => {
      getAllCharitiesForChapter(props.chapter.id);
    });

    const getAllCharitiesForChapter = async (chapterId, search = '') => {
      await charitiesStore.getAllCharitiesForChapter(chapterId, search)
        .then(() => {
          isLoading.value = false;
        });
    };

    const debouncedSearch = debounce((search) => {
      if (search) {
        getAllCharitiesForChapter(props.chapter.id, search);
      } else {
        getAllCharitiesForChapter(props.chapter.id, '');
      }
    }, 250);

    // I18n
    const { t } = useI18n();

    const createCharity = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('charity[name]', modalStore.newCharity.name);
        formData.append('charity[chapter_id]', props.chapter.id);

        if (modalStore.newCharity.city) {
          formData.append('charity[city]', modalStore.newCharity.city);
        }
        if (modalStore.newCharity.description) {
          formData.append('charity[description]', modalStore.newCharity.description);
        }
        if (modalStore.newCharity.address) {
          formData.append('charity[address]', modalStore.newCharity.address);
        }
        if (modalStore.newCharity.video_url) {
          formData.append('charity[video_url]', modalStore.newCharity.video_url);
        }
        if (modalStore.image) {
          formData.append('charity[image]', modalStore.image);
        }

        charitiesStore.newCharity(formData)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              resolve(response);
            }
          })
          .then(async () => {
            await getAllCharitiesForChapter(props.chapter.id);
            modalStore.resetModal();
          })
          .catch(error => {
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const editCharity = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('charity[name]', modalStore.newCharity.name);

        if (modalStore.newCharity.city) {
          formData.append('charity[city]', modalStore.newCharity.city);
        }
        if (modalStore.newCharity.description) {
          formData.append('charity[description]', modalStore.newCharity.description);
        }
        if (modalStore.newCharity.address) {
          formData.append('charity[address]', modalStore.newCharity.address);
        }
        if (modalStore.newCharity.video_url) {
          formData.append('charity[video_url]', modalStore.newCharity.video_url);
        }
        if (modalStore.image) {
          formData.append('charity[image]', modalStore.image);
        }

        charitiesStore.updateCharity(formData, modalStore.newCharity.id)
          .then(async (response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              resolve(response);
            }
          })
          .then(async () => {
            await getAllCharitiesForChapter(props.chapter.id);
            modalStore.resetModal();
          })
          .catch(error => {
            isLoading.value = false;
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const archiveCharity = () => {
      new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append('charity[archived]', true);

        charitiesStore.updateCharity(formData, modalStore.modalTypeExtraParam.id)
          .then(() => {
            modalStore.resetModal();
            resolve();
          })
          .catch(error => {
            isLoading.value = false;
            reject(error);
          });
      })
      .then(async () => {
        await getAllCharitiesForChapter(props.chapter.id);
      })
      .catch(error => {
        isLoading.value = false;
        modalStore.setPositiveHandleTrigger(false);
        modalStore.convertErrorsToMessages(error);
      });
    };

    watch(searchTerm, debouncedSearch, { immediate: false });

    watch(() => modalStore.positiveHandleTrigger, (value) => {
      if (value) {
        switch (modalStore.modalType) {
          case 'new_charity':
            createCharity();
            break;
          case 'edit_charity':
            editCharity();
            break;
          case 'cancel_charity':
            archiveCharity();
            break;
        }
      }
    });

    return {
      props,
      t,
      isLoading,
      searchTerm,
      charitiesStore,
      modalStore,
    };
  }
};
</script>

<template>
  <div>
    <div v-if="props.chapter && !isLoading" class="overflow-x-auto m-3">
      <div class="flex justify-between items-center">
        <p class="text-lg font-weight-600 leading-tight text-primary-color dark:text-neutral-50">
          {{ t('header.manage') }} {{ t('manage.chapter.charities') }}
        </p>
        <button type="button" class="primary-button text-sm approve" @click="modalStore.setModal('new_charity')">
          {{ t('manage.chapter.add_charity') }}
        </button>
      </div>
      <input type="text" v-model="searchTerm" :placeholder="t('manage.chapter.search_placeholder')" class="mt-2 w-full px-4 py-2 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">
      <p class="text-sm mb-1 mt-2">{{ t('manage.chapter.showing') }} {{ charitiesStore.allCharities.length }} {{ t('manage.chapter.charities') }}</p>
      <div class="overflow-x-auto" id="accordion-wrapper">
        <div v-for="(charity, accordionIndex) in charitiesStore.allCharities" :key="charity.id" class="border-t border-gray-300 w-auto">
          <button
            class="group w-full"
            type="button"
            data-te-collapse-init
            data-te-collapse-collapsed
            :data-te-target="`#collapse${accordionIndex}`"
            aria-expanded="false"
            :aria-controls="`collapse${accordionIndex}`">
            <div class="flex justify-between items-center py-2">
              <div class="flex items-center">
                <img
                  src="@/assets/icons/account_circle_FILL0_wght400_GRAD0_opsz24.svg"
                  class="rounded-full w-[50px]"
                  alt=""
                  loading="lazy" />
                <div class="flex flex-col text-left pl-2 truncate">
                  <p class="text-md font-medium">{{ charity.name }}</p>
                </div>
              </div>
            </div>
          </button>
          <div
            :id="`collapse${accordionIndex}`"
            class="!visible hidden"
            data-te-collapse-item
            data-te-parent="#accordion-wrapper"
            :aria-labelledby="`heading${accordionIndex}`">
            <div class="px-2 pb-4 mt-1">
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.address') }}</p>
                <p class="text-xs">{{ charity.address }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.city') }}</p>
                <p class="text-xs">{{ charity.city }}</p>
              </div>
              <div v-if="charity.description" class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.events_section.description') }}</p>
              </div>
              <div v-if="charity.description" class="flex justify-end py-1 border-t border-gray-300">
                <p class="text-xs">{{ charity.description }}</p>
              </div>
              <div v-if="charity.video_url" class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.charities_section.video') }}</p>
              </div>
              <div v-if="charity.video_url" class="flex justify-center items-center pb-4 pt-1">
                <div class="w-full lg:w-3/4 xl:w-2/3">
                  <div class="video-container">
                    <iframe
                      :src="charity.video_url"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    >
                    </iframe>
                  </div>
                </div>
              </div>
              <div v-if="charity.image_url" class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.charities_section.image') }}</p>
              </div>
              <div v-if="charity.image_url" class="flex justify-center">
                <img :src="charity.image_url" class="object-scale-down w-2/3 h-auto">
              </div>
              <div class="flex justify-between items-center py-1">
                <button type="button" class="primary-button text-sm deny" @click="modalStore.setModal('cancel_charity', charity)">
                  {{ t('manage.chapter.charities_section.delete_charity') }}
                </button>
                <button type="button" class="primary-button text-sm edit" @click="modalStore.setModal('edit_charity', charity)">
                  {{ t('manage.chapter.events_section.edit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
