<script>
import {onMounted, ref, watch, watchEffect} from "vue";
import { useI18n } from "vue-i18n";

// Stores
import { useChaptersStore } from '@/javascript/stores/chapters.js';
import { useCharitiesStore } from '@/javascript/stores/charities.js';
import { useVotingEventsStore } from '@/javascript/stores/voting_events.js';
import { useVotingEventCharitiesStore } from '@/javascript/stores/voting_event_charities.js';
import { useMembersStore } from '@/javascript/stores/members.js';
import { useVotesStore } from '@/javascript/stores/votes.js';
import { useVotingStore } from '@/javascript/stores/voting.js';
import { useModalStore } from '@/javascript/stores/modal.js';
import router from "@/javascript/router/router.js";

// Components

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    // Stores
    const chaptersStore = useChaptersStore();
    const charitiesStore = useCharitiesStore();
    const votingEventsStore = useVotingEventsStore();
    const votingEventCharitiesStore = useVotingEventCharitiesStore();
    const membersStore = useMembersStore();
    const votingStore = useVotingStore();
    const votesStore = useVotesStore();
    const modalStore = useModalStore();

    // I18n
    const { t } = useI18n();

    // Variables
    const isLoading = ref(true);
    const isRefreshing = ref(true);
    const currentChapter = ref(null);
    const currentMember = ref(null);
    const currentVotingEvent = ref(null);
    const currentVotingEventCharities = ref(null);
    const currentCharities = ref(null);
    const charityCounts = ref(null);
    const allVotes = ref([]);
    const approvedVotes = ref([]);
    const pendingVotes = ref([]);
    const pendingVoteMembers = ref([]);
    const progressColours = ref(["#8Ca47F", "#a86060", "#5d89b4"]);

    onMounted(async () => {
      await getVotingActivity();

      // Set polling for live data refresh
      const poll = async () => {
        isRefreshing.value = true;
        await pollVotingData();
        setTimeout(poll, 15000);
      };
      await poll();
    });

    watchEffect(() => {
      // initTE({ Ripple }, { allowReinits: true });
    });

    const getChapterFromUniquePath = (path) => {
      return chaptersStore.getChapterFromPath(path);
    };

    const getMemberForChapter = (chapterId) => {
      return membersStore.getMemberByChapterId(chapterId, document.body.dataset.currentUserId);
    };

    const getVotingEventForChapter = (chapterId) => {
      return votingEventsStore.getVotingEventByChapterId(chapterId);
    };

    const getCharityFromId = (charityId) => {
      return currentCharities.value.find((charity) => { return charity.id === charityId; });
    };

    const getVotesForCharity = (votingEventCharityId) => {
      return approvedVotes.value.filter((vote) => { return vote.voting_event_charity_id === votingEventCharityId; });
    };

    const getApprovedMembersForVoting = (votes) => {
      let members =  membersStore.approvedMembers.filter((member) => {
        // Check if there's any vote in approvedVotes that matches the member's user_id
        return member.name !== null && votes.some((vote) => vote.user_id && vote.user_id === member.user.id);
      });

      // Add manual votes to the list
      votes.filter((vote) => vote.name && !vote.user_id).forEach((vote) => members.push({
        name: vote.name,
        role: t('voting_results.manual_vote')
      }));

      // TODO: Sort manual entries as well before we push them to the members list

      return members;
    };

    const getVotingActivity = async () => {
      let chapterParams = new URLSearchParams();
      chapterParams.set('query[archived_eq]', 'false');

      let memberParams = new URLSearchParams();
      memberParams.set('query[user_id_eq]', document.body.dataset.currentUserId);
      memberParams.set('query[archived_eq]', 'false');

      const estDate = new Date(new Date().getTime());
      let votingEventParams = new URLSearchParams();
      votingEventParams.set('query[end_time_gteq]', estDate);
      votingEventParams.set('query[s]', 'start_time ASC');
      votingEventParams.set('query[archived_eq]', 'false');

      try {
        await Promise.all([
          chaptersStore.getChapters(chapterParams),
          membersStore.getMembers(memberParams),
          votingEventsStore.getVotingEvents(votingEventParams),
        ]);

        currentChapter.value = getChapterFromUniquePath(props.id);
        votingStore.setChapterSelection(currentChapter.value);
      } catch (error) {
        console.error(error);
      }
    };

    const pollVotingData = async () => {
      await membersStore.getApprovedMembersForChapter(currentChapter.value.id);
      await membersStore.getPendingMembersForChapter(currentChapter.value.id);

      currentVotingEvent.value = getVotingEventForChapter(currentChapter.value.id);
      currentMember.value = getMemberForChapter(currentChapter.value.id);

      // Check if user has privileges to be on this page, if not return them to the voting page
      if (currentMember.value.status !== 'Approved' || currentMember.value.role !== "Organizer") {
        router.go(-1);
      }

      if (currentVotingEvent.value) {
        let params = new URLSearchParams();
        params.set('query[voting_event_id_eq]', currentVotingEvent.value.id);
        params.set('query[archived_eq]', 'false');

        // Get voting_event_charities based on the current voting_event
        currentVotingEventCharities.value = await votingEventCharitiesStore.getVotingEventCharities(params);

        if (currentVotingEventCharities.value) {
          let params = new URLSearchParams();
          params.set('query[s]', 'name ASC');
          params.set('query[archived_eq]', 'false');
          currentVotingEventCharities.value.map(cvec => {
            params.append('query[id_in][]', cvec.charity_id);
          });

          // Get charities based on voting_event_charities
          currentCharities.value = await charitiesStore.getCharities(params);
        }

        // Get approved and unapproved votes
        let voteParams = new URLSearchParams();
        voteParams.set('query[voting_event_id_eq]', currentVotingEvent.value.id);
        voteParams.set('query[archived_eq]', 'false');

        allVotes.value = await votesStore.getVotes(voteParams, true);

        if (allVotes.value) {
          approvedVotes.value = await filterApprovedVotes();
          pendingVotes.value = await filterPendingVotes();
          pendingVoteMembers.value = await filterPendingVoteMembers();
          charityCounts.value = await mapCharityCounts();
        }
      } else {
        // No event is scheduled, kick them back
        router.go(-1);
      }

      isRefreshing.value = false;
      isLoading.value = false;
    };

    const filterApprovedVotes = async () => {
      return allVotes.value.filter((vote) => {
        if (vote.name && !vote.user_id) {
          return true;
        } else {
          const member = membersStore.getApprovedMemberByUserId(vote.user_id);
          return member && member.status === 'Approved';
        }
      });
    };

    const filterPendingVotes = async () => {
      return allVotes.value.filter((vote) => {
        if (!vote.name && vote.user_id) {
          const member = membersStore.getPendingMemberByUserId(vote.user_id);
          return member && member.status === 'Pending';
        } else {
          return false;
        }
      });
    };

    const filterPendingVoteMembers = async () => {
      return membersStore.pendingMembers.filter((member) => {
        return pendingVotes.value.some((vote) => vote.user_id === member.user.id);
      });
    };

    const mapCharityCounts = async () => {
      let data = currentVotingEventCharities.value.map((vec, index) => {
        let votes = getVotesForCharity(vec.id);
        let charity = getCharityFromId(vec.charity_id);

        return {
          key: vec.id,
          charity: charity,
          votes: votes,
          percentage: Math.round((votes.length / approvedVotes.value.length) * 100),
          colour: progressColours.value[index % progressColours.value.length],
          members: getApprovedMembersForVoting(votes),
        };
      });
      return data.sort((a, b) => b.votes.length - a.votes.length);
    };

    const updateMember = (status) => {
      new Promise((resolve, reject) => {
        let member = modalStore.modalTypeExtraParam;
        member.approver_id = document.body.dataset.currentUserId;
        member.status = status;

        membersStore.updateMember(member)
          .then(() => {
            modalStore.resetModal();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      })
      .then(async () => {
        isRefreshing.value = true;
        await pollVotingData();
      });
    };

    const saveManualVote = () => {
      new Promise((resolve, reject) => {
        let currentVotingCharity = currentVotingEventCharities.value.find((vec) => { return vec.charity_id === modalStore.manualVote.voting_event_charity_id; });

        let data = {
          voting_event_charity_id: currentVotingCharity.id,
          voting_event_id: currentVotingEvent.value.id,
          name: modalStore.manualVote.name
        };

        votesStore.newVote(data)
          .then(() => {
            modalStore.resetModal();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      })
      .then(async () => {
        isRefreshing.value = true;
        await pollVotingData();
      });
    };

    watch(() => modalStore.positiveHandleTrigger, (value) => {
      if (value) {
        switch (modalStore.modalType) {
          case 'approve_member':
            updateMember('Approved');
            break;
          case 'deny_member':
            updateMember('Denied');
            break;
          case 'manual_vote':
            saveManualVote();
            break;
        }
      }
    });

    return {
      t,
      props,
      chaptersStore,
      votingEventsStore,
      membersStore,
      votingStore,
      modalStore,
      isLoading,
      isRefreshing,
      currentChapter,
      currentMember,
      currentVotingEvent,
      currentVotingEventCharities,
      currentCharities,
      charityCounts,
      allVotes,
      approvedVotes,
      pendingVotes,
      pendingVoteMembers,
      getMemberForChapter,
      getVotingEventForChapter,
      getCharityFromId,
      getVotesForCharity,
    };
  }
};

</script>

<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex items-center justify-center justify-between mb-2 h-[30px]">
        <router-link :to="'/voting/' + currentChapter.unique_path">
          <div class="flex justify-start items-center back-button">
            <img src="@/assets/icons/arrow_back_ios_new_FILL0_wght400_GRAD0_opsz24.svg" class="mr-1" alt="Back Icon" />
            {{ t('voting.back') }}
          </div>
        </router-link>
        <div v-if="isRefreshing" class="flex items-center justify-center primary-button info">
          <span class="font-weight-400">
            {{ t('voting_results.refreshing_data') }}
          </span>
          <div class="inline-block h-4 w-4 ml-5 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-center justify-between mt-2 mb-2">
        <h4 class="title-h4 text-primary-color">
          {{ t('voting_results.approved_results') }}
        </h4>
        <h3 class="title-h3 text-primary-color">
          {{ approvedVotes.length }}
        </h3>
      </div>
      <div class="block rounded-lg bg-white p-3 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 mb-15">
        <div v-for="(votingEventCharity) in charityCounts" :key="votingEventCharity.key" class="mb-3" @click="modalStore.setModal('approved_votes', votingEventCharity)">
          <div class="flex items-center justify-center justify-between mb-1">
            <p class="font-weight-500">{{ votingEventCharity.charity ? votingEventCharity.charity.name : "Charity Name" }}</p>
            <p class="font-weight-600">{{ votingEventCharity.votes.length }}</p>
          </div>
          <div class="completion-bar cursor-pointer">
            <div class="completion-fill" :style="{ width: `${votingEventCharity.percentage}%`, backgroundColor: `${votingEventCharity.colour}`}"></div>
          </div>
        </div>
        <div v-if="currentMember.status === 'Approved' && currentMember.role === 'Organizer'" class="flex items-center justify-center">
          <button type="button" class="secondary-button mt-5" @click="modalStore.setModal('manual_vote', currentCharities)">
            <div class="flex justify-start items-center">
              {{ t('voting_results.enter_manual_vote') }}
            </div>
          </button>
        </div>
      </div>
      <div v-if="currentMember.status === 'Approved' && currentMember.role === 'Organizer'">
        <div class="flex items-center justify-center justify-between mt-2 mb-2">
          <h4 class="title-h4 text-primary-color">
            {{ t('voting_results.pending_results') }}
          </h4>
          <h3 class="title-h3 text-primary-color">
            {{ pendingVotes.length }}
          </h3>
        </div>
        <div v-if="pendingVoteMembers.length == 0" class="flex">
          <p class="text-center w-full font-medium">{{ t('voting_results.pending_results_none') }}</p>
        </div>
        <div v-else v-for="(member) in pendingVoteMembers" :key="member.id" class="block rounded-lg bg-white p-2 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700 mb-2">
          <div class="flex items-center justify-between">
            <div class="flex flex-col items-start flex-grow">
              <p class="font-weight-500">
                {{ member.user.first_name }} {{ member.user.last_name }}
              </p>
            </div>
            <div class="flex justify-end items-end">
              <button type="button" class="primary-button deny" @click="modalStore.setModal('deny_member', member)">
                {{ t('voting_results.deny') }}
              </button>
              <button type="button" class="primary-button approve ml-5" @click="modalStore.setModal('approve_member', member)">
                {{ t('voting_results.approve') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
