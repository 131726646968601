import { ref } from 'vue';
import { defineStore } from 'pinia';
import { useUsersStore } from "@/javascript/stores/users.js";

export const useAuthenticationStore = defineStore('authentication', () => {
  const currentUserKey = ref('current_user');
  const currentUser = ref(null);

  // Stores
  const usersStore = useUsersStore();

  const setUserCurrentUser = (user) => {
    currentUser.value = user;
    localStorage.setItem(currentUserKey.value, JSON.stringify(currentUser.value));
  };

  const loadUserData = async (userId) => {
    const savedUser = localStorage.getItem(currentUserKey.value);

    // If no saved user found, call for the user and store them
    if (savedUser) {
      currentUser.value = JSON.parse(savedUser);
    } else {
      usersStore.getUser(userId)
        .then((user) => {
          setUserCurrentUser(user);
        });
    }
  };

  const clearUserData = async () => {
    localStorage.removeItem(currentUserKey.value);
  };

  return {
    currentUser,
    setUserCurrentUser,
    loadUserData,
    clearUserData,
  };
});
