<script>
import {onMounted, ref, watch, computed} from "vue";
import { useI18n } from "vue-i18n";
import { marked } from "marked";
import { formatDate, formatTimeRange, isEventInPast, convertToUTC } from "@/javascript/lib/date_helper.js";

// Stores
import { useMembersStore } from '@/javascript/stores/members.js';
import { useVotingEventsStore } from '@/javascript/stores/voting_events.js';
import { useVotingEventCharitiesStore } from '@/javascript/stores/voting_event_charities.js';
import { useCharitiesStore } from '@/javascript/stores/charities.js';
import { useChaptersStore } from '@/javascript/stores/chapters.js';
import { useModalStore } from '@/javascript/stores/modal.js';

export default {
  props: {
    chapter: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    // Stores
    const membersStore = useMembersStore();
    const votingEventsStore = useVotingEventsStore();
    const votingEventCharitiesStore = useVotingEventCharitiesStore();
    const charitiesStore = useCharitiesStore();
    const chaptersStore = useChaptersStore();
    const modalStore = useModalStore();

    // Variables
    const isLoading = ref(true);
    const currentVotingEventCharities = ref(null);
    const currentChapter = ref(null);
    const chapterCharities = ref(null);

    onMounted(() => {
      currentChapter.value = chaptersStore.chapters.find(chapter => chapter.id === props.chapter.id);
      getVotingEvents();
    });

    const winningCharity = (event) => {
      return event.winning_charity.reduce((max, charity) => (charity.value > max.value ? charity : max));
    };

    const getVotingEvents = () => {
      let votingEventParams = new URLSearchParams();
      votingEventParams.set('query[s]', 'start_time DESC');
      votingEventParams.set('query[archived_eq]', 'false');
      votingEventParams.set('query[chapter_id_eq]', currentChapter.value.id);

      votingEventsStore.getVotingEvents(votingEventParams)
        .then(() => {
          return getCharities();
        });
    };

    const getCharities = async () => {
      // Get all relevant charities to the current chapter
      let params = new URLSearchParams();
      params.set('query[s]', 'name ASC');
      params.set('query[archived_eq]', 'false');
      params.set('query[chapter_id_eq]', currentChapter.value.id);
      chapterCharities.value = await charitiesStore.getCharities(params);

      // Get all voting event charities for this chapter
      currentVotingEventCharities.value = await votingEventCharitiesStore.getVotingEventCharities(params);

      sortCharities();

      return true;
    };

    const sortCharities = () => {
      votingEventsStore.votingEvents.forEach(event => {
        let currentEventCharities = currentVotingEventCharities.value.filter(cvec => cvec.voting_event_id === event.id);
        let votingEventCharityIds = currentEventCharities.map(cvec => { return cvec.charity_id; } );
        event.current_charities = chapterCharities.value.filter((charity) => { return votingEventCharityIds.includes(charity.id); });
      });

      isLoading.value = false;
    };

    // I18n
    const { t } = useI18n();

    const convertMarkdownToHTML = (text) => {
      return marked.parse(text);
    };

    const createEvent = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let event = {
          start_time: convertToUTC(modalStore.newEvent.start_time),
          end_time: convertToUTC(modalStore.newEvent.end_time),
          address: modalStore.newEvent.address,
          city: modalStore.newEvent.city,
          postal_code: modalStore.newEvent.postal_code,
          description: modalStore.newEvent.description,
          chapter_id: currentChapter.value.id
        };

        votingEventsStore.createVotingEvent(event)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              let event = response;
              const newCharities = modalStore.modalTypeExtraParam.selectedCharities.filter(update => !modalStore.modalTypeExtraParam.originalCharities.includes(update)).map(update => update);

              // Make all API calls concurrently
              const requests = newCharities.map(charityId => {
                let votingEventCharity = {
                  charity_id: charityId,
                  voting_event_id: event.id
                };

                votingEventCharitiesStore.createVotingEventCharity(votingEventCharity);
              });
              return Promise.all(requests);
            }
          })
          .then(async () => {
            await getVotingEvents();
            modalStore.resetModal();
          })
          .catch(error => {
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const editEvent = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let event = {
          id: modalStore.newEvent.id,
          start_time: convertToUTC(modalStore.newEvent.start_time),
          end_time: convertToUTC(modalStore.newEvent.end_time),
          address: modalStore.newEvent.address,
          city: modalStore.newEvent.city,
          postal_code: modalStore.newEvent.postal_code,
          description: modalStore.newEvent.description
        };

        votingEventsStore.updateVotingEvent(event)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              // Determine new/deleted IDs for charity for this event and also call/update those as well as the event.
              const additionCharities = modalStore.modalTypeExtraParam.selectedCharities.filter(update => !modalStore.modalTypeExtraParam.originalCharities.includes(update)).map(update => update);
              const removalCharities = modalStore.modalTypeExtraParam.originalCharities.filter(id => !modalStore.modalTypeExtraParam.selectedCharities.includes(id));

              // Get list of current event voting event charities list
              let currentEventCharities = currentVotingEventCharities.value.filter(cvec => cvec.voting_event_id === event.id);

              // Make all API calls concurrently
              const requests = removalCharities.map(charityId => votingEventCharitiesStore.deleteVotingEventCharity(currentEventCharities.find(charity => charity.charity_id === charityId).id));
              additionCharities.forEach((charityId) => {
                let votingEventCharity = {
                  charity_id: charityId,
                  voting_event_id: event.id
                };

                // Add addition requests to promise.all
                requests.push(votingEventCharitiesStore.createVotingEventCharity(votingEventCharity));
              });

              return Promise.all(requests);
            }
          })
          .then(async () => {
            await getVotingEvents();
            modalStore.resetModal();
          })
          .catch(error => {
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const archiveEvent = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let event = modalStore.modalTypeExtraParam;
        event.archived = true;

        votingEventsStore.updateVotingEvent(event)
          .then(() => {
            modalStore.resetModal();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      })
      .then(async () => {
        await getVotingEvents();
      });
    };

    watch(() => modalStore.positiveHandleTrigger, (value) => {
      if (value) {
        switch (modalStore.modalType) {
          case 'new_event':
            createEvent();
            break;
          case 'edit_event':
            editEvent();
            break;
          case 'cancel_event':
            archiveEvent();
            break;
        }
      }
    });

    return {
      props,
      t,
      membersStore,
      charitiesStore,
      votingEventCharitiesStore,
      chapterCharities,
      currentVotingEventCharities,
      votingEventsStore,
      modalStore,
      formatDate,
      formatTimeRange,
      isEventInPast,
      isLoading,
      convertMarkdownToHTML,
      winningCharity
    };
  }
};
</script>

<template>
  <div>
    <div v-if="props.chapter && !isLoading" class="overflow-x-auto m-3">
      <div class="flex justify-between items-center">
        <p class="text-lg font-weight-600 leading-tight text-primary-color dark:text-neutral-50">
          {{ t('header.manage') }} {{ t('manage.chapter.events') }}
        </p>
        <button type="button" class="primary-button text-sm approve" @click="modalStore.setModal('new_event', { event: null, currentCharities: chapterCharities, selectedCharities: [], originalCharities: [] })">
          {{ t('manage.chapter.add_event') }}
        </button>
      </div>
      <p class="text-sm mb-1 mt-2">{{ t('manage.chapter.showing') }} {{ votingEventsStore.votingEvents.length }} {{ t('manage.chapter.events') }}</p>
      <div class="overflow-x-auto" id="accordion-wrapper">
        <div v-for="(event, accordionIndex) in votingEventsStore.votingEvents" :key="event.id" class="border-t border-gray-300 w-auto">
          <button
            class="group w-full"
            type="button"
            data-te-collapse-init
            data-te-collapse-collapsed
            :data-te-target="`#collapse${accordionIndex}`"
            aria-expanded="false"
            :aria-controls="`collapse${accordionIndex}`">
            <div class="flex justify-between items-center py-2">
              <div class="flex items-center">
                <div class="flex flex-col text-left pl-2 truncate">
                  <p class="text-sm">{{ formatDate(event.start_time) }}</p>
                  <p class="text-sm">{{ formatTimeRange(event.start_time, event.end_time) }}</p>
                </div>
              </div>
              <div :class="`${isEventInPast(event.end_time) ? 'pending' : 'voted'} pill text-xs`">
                <span>{{ isEventInPast(event.end_time) ? t('manage.chapter.events_section.past_event') : t('manage.chapter.events_section.upcoming_event') }}</span>
              </div>
            </div>
          </button>
          <div
            :id="`collapse${accordionIndex}`"
            class="!visible hidden"
            data-te-collapse-item
            data-te-parent="#accordion-wrapper"
            :aria-labelledby="`heading${accordionIndex}`">
            <div class="px-2 pb-4 mt-1">
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.address') }}</p>
                <p class="text-xs">{{ event.address }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.city') }}</p>
                <p class="text-xs">{{ event.city }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.postal_code') }}</p>
                <p class="text-xs">{{ event.zip_code }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.events_section.charities') }}</p>
              </div>
              <div v-for="(charity, index) in event.current_charities" :key="'charity-' + index" class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs">{{ charity.name }}</p>
                <div class="flex flex-row items-center">
                  <div v-if="event.winning_charity && winningCharity(event).charity_id === charity.id" class="voted pill text-xs py-0 mr-2">
                  <span>{{ t('manage.chapter.events_section.winner') }}</span>
                </div>
                <p class="text-xs" v-if="event.winning_charity && event.winning_charity.filter(chrty => chrty.charity_id === charity.id).length > 0">{{ event.winning_charity && event.winning_charity.filter(chrty => chrty.charity_id === charity.id)[0].votes_count }} {{ t('manage.chapter.events_section.votes') }}</p>
                <p class="text-xs" v-else>0 {{ t('manage.chapter.events_section.votes') }}</p>
                </div>
              </div>
              <div v-if="event.current_charities && event.current_charities.length === 0">
                <p class="text-xs">{{ t('manage.chapter.events_section.no_charities') }}</p>
              </div>
              <div v-if="event.description" class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.events_section.description') }}</p>
              </div>
              <div v-if="event.description" class="flex justify-end py-1 border-t border-gray-300">
                <p class="text-xs" v-html="convertMarkdownToHTML(event.description)"></p>
              </div>
              <div v-if="!isEventInPast(event.end_time)" class="flex justify-between items-center py-1">
                <button type="button" class="primary-button text-sm deny" @click="modalStore.setModal('cancel_event', event)">
                  {{ t('manage.chapter.events_section.cancel_event') }}
                </button>
                <button type="button" class="primary-button text-sm edit" @click="modalStore.setModal('edit_event', { event: event, currentCharities: chapterCharities, selectedCharities: event.current_charities.map(charity => charity.id), originalCharities: event.current_charities.map(charity => charity.id) })">
                  {{ t('manage.chapter.events_section.edit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
