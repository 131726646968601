<script>
import {computed, onMounted} from "vue";
import { useI18n } from "vue-i18n";
import { isEventLive } from "@/javascript/lib/date_helper.js";

// Stores
import { useVotingStore } from '@/javascript/stores/voting.js';

export default {
  props: {
    charity: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    votingEvent: {
      type: Object,
      required: false,
      default: null,
    },
    currentVote: {
      type: Array,
      required: true,
    },
    accordionIndex: {
      type: Number,
      required: true,
    },
    teamVote: {
      type: Object,
      required: false,
    }
  },

  setup(props) {
    // Stores
    const votingStore = useVotingStore();

    onMounted(() => {
      // Initialize TW Elements
      // initTE({ Collapse, Ripple }, { allowReinits: true });
    });

    // I18n
    const { t } = useI18n();

    const isChecked = computed(() => votingStore.charitySelection && votingStore.charitySelection.id === props.charity.id);

    return {
      props,
      t,
      votingStore,
      isChecked,
      isEventLive,
    };
  }
};
</script>

<template>
  <div v-if="props.charity" class="block mb-10 rounded-lg bg-white p-1 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
    <div
      class="bg-white dark:border-neutral-600 dark:bg-neutral-800">
      <h2 class="mb-0 flex justify-between items-center" :id="`heading${accordionIndex}`">
        <input
          v-if="member.status !== 'Denied' && currentVote.length === 0 && isEventLive(votingEvent.start_time, votingEvent.end_time) && !teamVote"
          :checked="isChecked"
          class="charity-checkbox ml-4 appearance-none rounded-[0.25rem] border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)]"
          type="checkbox"
          :value="props.charity"
          @change="votingStore.setCharitySelection(props.charity)"
        />
        <button
          class="group relative flex justify-between w-full items-center px-5 py-4 text-left text-base text-lg font-weight-500 leading-tight text-primary-color dark:text-neutral-50 [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none dark:bg-neutral-800 dark:text-white"
          type="button"
          data-te-collapse-init
          data-te-collapse-collapsed
          :data-te-target="`#collapse${accordionIndex}`"
          aria-expanded="false"
          :aria-controls="`collapse${accordionIndex}`">
          {{ charity.name }}
          <div class="group relative flex items-center">
            <div v-if="currentVote.length > 0 && currentVote[0].charity_id === charity.id && member.status !== 'Denied'" class="voted pill ml-2">
              <span>{{ t('voting.voted') }}</span>
            </div>
            <div v-else-if="teamVote && teamVote.charity.id === charity.id" class="voted pill ml-2">
              <span>{{ t('voting.team_voted') }}</span>
            </div>
            <span
              class="ml-2 h-5 w-5 shrink-0 rotate-[-180deg] fill-[#336dec] transition-transform duration-200 ease-in-out group-[[data-te-collapse-collapsed]]:rotate-0 group-[[data-te-collapse-collapsed]]:fill-[#212529] motion-reduce:transition-none dark:fill-blue-300 dark:group-[[data-te-collapse-collapsed]]:fill-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </span>
          </div>
        </button>
      </h2>
      <div
        :id="`collapse${accordionIndex}`"
        class="!visible hidden"
        data-te-collapse-item
        data-te-parent="#accordion-wrapper"
        :aria-labelledby="`heading${accordionIndex}`">
        <div v-if="props.charity.image_url" class="flex justify-center">
          <img :src="props.charity.image_url" class="object-scale-down w-2/3 h-auto" alt="Charity Logo" />
        </div>
        <div class="px-5 pb-4" v-html="props.charity.description" />
        <div class="flex justify-center items-center px-5 pb-4" v-if="props.charity.video_url">
          <div class="w-full lg:w-3/4 xl:w-2/3">
            <div class="video-container">
              <iframe
                :src="props.charity.video_url"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
