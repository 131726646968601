<script>
import {onMounted, ref, watchEffect} from "vue";
import { useI18n } from "vue-i18n";
import {
  Tab,
  initTE,
} from "tw-elements";
import router from "@/javascript/router/router.js";

// Components
import MembersList from "@/javascript/views/manage/components/members_list.vue";
import EventsList from "@/javascript/views/manage/components/events_list.vue";
import CharitiesList from "@/javascript/views/manage/components/charities_list.vue";
import TeamsList from "@/javascript/views/manage/components/teams_list.vue";

// Stores
import { useChaptersStore } from '@/javascript/stores/chapters.js';
import { useVotingEventsStore } from '@/javascript/stores/voting_events.js';
import { useMembersStore } from '@/javascript/stores/members.js';
import { useVotingStore } from '@/javascript/stores/voting.js';
import { useModalStore } from '@/javascript/stores/modal.js';

export default {
  components: { MembersList, EventsList, CharitiesList, TeamsList },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    // Stores
    const chaptersStore = useChaptersStore();
    const votingEventsStore = useVotingEventsStore();
    const membersStore = useMembersStore();
    const votingStore = useVotingStore();
    const modalStore = useModalStore();

    // I18n
    const { t } = useI18n();

    // Variables
    const isLoading = ref(true);
    const currentActiveTab = ref('members');
    const componentKey = ref(0);
    const currentChapter = ref(null);
    const currentMember = ref(null);
    const currentVotingEvent = ref(null);
    const currentVotingEventCharities = ref(null);
    const currentCharities = ref(null);
    const charityCounts = ref(null);
    const allVotes = ref([]);
    const approvedVotes = ref([]);
    const pendingVotes = ref([]);
    const pendingVoteMembers = ref([]);

    onMounted(async () => {
      initTE({ Tab });

      await getChapterActivity()
        .then(() => {
          isLoading.value = false;
        });
    });

    watchEffect(() => {
      initTE({ Tab }, { allowReinits: true });
    });

    const getChapterActivity = async () => {
      let chapterParams = new URLSearchParams();
      chapterParams.set('query[archived_eq]', 'false');

      let memberParams = new URLSearchParams();
      memberParams.set('query[user_id_eq]', document.body.dataset.currentUserId);
      memberParams.set('query[archived_eq]', 'false');

      const estDate = new Date(new Date().getTime());
      let votingEventParams = new URLSearchParams();
      votingEventParams.set('query[end_time_gteq]', estDate);
      votingEventParams.set('query[s]', 'start_time ASC');
      votingEventParams.set('query[archived_eq]', 'false');

      try {
        await Promise.all([
          chaptersStore.getChapters(chapterParams),
          membersStore.getMembers(memberParams),
          votingEventsStore.getVotingEvents(votingEventParams),
        ]);

        currentChapter.value = getChapterFromUniquePath(props.id);
        votingStore.setChapterSelection(currentChapter.value);

        if (currentChapter.value) {
          currentMember.value = getMemberForChapter(currentChapter.value.id);
        }

        // Check if user has privileges to be on this page, if not return them to the voting page
        if (currentMember.value && currentMember.value.status !== 'Approved' || currentMember.value.role !== "Organizer") {
          router.go(-1);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getChapterFromUniquePath = (path) => {
      return chaptersStore.getChapterFromPath(path);
    };

    const getMemberForChapter = (chapterId) => {
      return membersStore.getMemberByChapterId(chapterId, document.body.dataset.currentUserId);
    };

    const getVotingEventForChapter = (chapterId) => {
      return votingEventsStore.getVotingEventByChapterId(chapterId);
    };

    const setActiveTab = (tab) => {
      currentActiveTab.value = tab;
      componentKey.value += 1;
    };

    return {
      t,
      props,
      chaptersStore,
      votingEventsStore,
      membersStore,
      votingStore,
      modalStore,
      isLoading,
      currentChapter,
      currentMember,
      currentVotingEvent,
      currentVotingEventCharities,
      currentCharities,
      charityCounts,
      allVotes,
      approvedVotes,
      pendingVotes,
      pendingVoteMembers,
      currentActiveTab,
      getMemberForChapter,
      getVotingEventForChapter,
      setActiveTab,
      componentKey,
    };
  }
};
</script>

<template>
  <div>
    <div v-if="!isLoading">
      <h5 v-if="currentChapter" class="text-xl font-weight-600 leading-tight text-primary-color dark:text-neutral-50">
        {{ currentChapter.name }}
      </h5>
      <ul
        class="mb-5 mt-1 flex list-none flex-row flex-wrap border-b-0 ps-0"
        role="tablist"
        data-twe-nav-ref>
        <li role="presentation">
          <a
            @click="setActiveTab('members')"
            class="my-1 block cursor-pointer border-x-0 border-b-2 border-t-0 border-transparent px-3 py-3 text-sm font-medium leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[twe-nav-active]:border-neutral-800 data-[twe-nav-active]:text-neutral-800 dark:text-white/50 dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:border-neutral-600 dark:data-[twe-nav-active]:text-neutral-600"
            data-twe-toggle="pill"
            role="tab"
            :data-twe-nav-active="currentActiveTab === 'members' ? '' : undefined"
            :aria-selected="currentActiveTab === 'members' ? '' : undefined"
          >{{ t('manage.chapter.members') }}</a>
        </li>
        <li role="presentation">
          <a
            @click="setActiveTab('events')"
            class="my-1 block cursor-pointer border-x-0 border-b-2 border-t-0 border-transparent px-3 py-3 text-sm font-medium leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[twe-nav-active]:border-neutral-800 data-[twe-nav-active]:text-neutral-800 dark:text-white/50 dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:border-neutral-600 dark:data-[twe-nav-active]:text-neutral-600"
            data-twe-toggle="pill"
            role="tab"
            :data-twe-nav-active="currentActiveTab === 'events' ? '' : undefined"
            :aria-selected="currentActiveTab === 'events' ? '' : undefined"
          >{{ t('manage.chapter.events') }}</a>
        </li>
        <li role="presentation">
          <a
            @click="setActiveTab('charities')"
            class="my-1 block cursor-pointer border-x-0 border-b-2 border-t-0 border-transparent px-3 py-3 text-sm font-medium leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[twe-nav-active]:border-neutral-800 data-[twe-nav-active]:text-neutral-800 dark:text-white/50 dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:border-neutral-600 dark:data-[twe-nav-active]:text-neutral-600"
            data-twe-toggle="pill"
            role="tab"
            :data-twe-nav-active="currentActiveTab === 'charities' ? '' : undefined"
            :aria-selected="currentActiveTab === 'charities' ? '' : undefined"
          >{{ t('manage.chapter.charities') }}</a>
        </li>
        <li role="presentation">
          <a
            @click="setActiveTab('teams')"
            class="my-1 block cursor-pointer border-x-0 border-b-2 border-t-0 border-transparent px-3 py-3 text-sm font-medium leading-tight text-neutral-500 hover:isolate hover:border-transparent hover:bg-neutral-100 focus:isolate focus:border-transparent data-[twe-nav-active]:border-neutral-800 data-[twe-nav-active]:text-neutral-800 dark:text-white/50 dark:hover:bg-neutral-700/60 dark:data-[twe-nav-active]:border-neutral-600 dark:data-[twe-nav-active]:text-neutral-600"
            data-twe-toggle="pill"
            role="tab"
            :data-twe-nav-active="currentActiveTab === 'teams' ? '' : undefined"
            :aria-selected="currentActiveTab === 'teams' ? '' : undefined"
          >{{ t('manage.chapter.teams') }}</a>
        </li>
      </ul>

      <!--Tabs content-->
      <div class="grid grid-cols-1">
        <div
          v-if="currentActiveTab === 'members'"
          class="block mb-15 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <MembersList
            :chapter="currentChapter"
            :key="componentKey"
          ></MembersList>
        </div>
        <div
          v-if="currentActiveTab === 'events'"
          class="block mb-15 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <EventsList
            :chapter="currentChapter"
            :key="componentKey"
          ></EventsList>
        </div>
        <div
          v-if="currentActiveTab === 'charities'"
          class="block mb-15 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <CharitiesList
            :chapter="currentChapter"
            :key="componentKey"
          ></CharitiesList>
        </div>
        <div
          v-if="currentActiveTab === 'teams'"
          class="block mb-15 rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
          <TeamsList
            :chapter="currentChapter"
            :key="componentKey"
          ></TeamsList>
        </div>
      </div>
    </div>

    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
