// router.js
import { createRouter, createWebHistory } from 'vue-router';

// Components
import Dashboard from '@/javascript/views/dashboard/dashboard.vue';
import Voting from '@/javascript/views/voting/voting.vue';
import VotingResults from '@/javascript/views/voting_results/voting_results.vue';
import ManageChapter from '@/javascript/views/manage/manage_chapter.vue';

const routes = [
  { path: '/', redirect: '/dashboard' },
  { path: '/dashboard', component: Dashboard },
  { path: '/voting/:id', component: Voting, props: true },
  { path: '/voting/:id/results', component: VotingResults, props: true },
  { path: '/manage/:id', component: ManageChapter, props: true },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(() => {
  window.scrollTo(0, 0);
});

export default router;
