<script>
import { onMounted, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { formatDate, formatTimeRange, isEventLive } from "@/javascript/lib/date_helper.js";
import { useModalStore } from '@/javascript/stores/modal.js';
import { initTE, Popover } from "tw-elements";

export default {
  props: {
    chapter: {
      type: Object,
      required: true,
    },
    member: {
      type: Object,
      required: true,
    },
    votingEvent: {
      type: Object,
      required: false,
      default: null,
    }
  },

  setup(props) {
    const modalStore = useModalStore();
    const randomBoolean = Math.random() < 0.5;

    onMounted(() => {
      // Initialize TW Elements
      initTE({ Popover }, { allowReinits: true });
    });

    watchEffect(() => {
      initTE({ Popover }, { allowReinits: true });
    });

    // I18n
    const { t } = useI18n();

    const approvalText = () => {
      const confirmationPopoverPart1 = t('dashboard.charity_card.confirmation_popover_1');
      const confirmationPopoverPart2 = t('dashboard.charity_card.generic_popover_1');
      const confirmationPopoverPart3 = t('dashboard.charity_card.generic_popover_2');

      const roleName = props.member.role;
      const approverName = props.member.approver_user && props.member.approver_user.first_name && props.member.approver_user.last_name ?
        `${props.member.approver_user.first_name} ${props.member.approver_user.last_name}` :
        'an Organizer';
      const approvalDate = props.member.approved_at ? formatDate(props.member.approved_at) : '';

      if (props.member.status === 'Approved') {
        return `${confirmationPopoverPart1} ${roleName} ${confirmationPopoverPart2} ${approverName} ${approvalDate ? `${confirmationPopoverPart3} ${approvalDate}` : ''}.`.replace(/\s+/g, ' ');
      } else if (props.member.status === 'Pending') {
        const pendingPopoverText = t('dashboard.charity_card.pending_popover');
        return `${pendingPopoverText} ${props.chapter.name}.`.replace(/\s+/g, ' ');
      } else if (props.member.status === 'Denied') {
        const deniedPopoverPart1 = t('dashboard.charity_card.denied_popover_1');
        const deniedPopoverPart2 = t('dashboard.charity_card.generic_popover_1');
        const deniedPopoverPart3 = t('dashboard.charity_card.generic_popover_2');
        const deniedPopoverPart4 = t('dashboard.charity_card.denied_popover_2');

        return `${deniedPopoverPart1} ${roleName} ${deniedPopoverPart2} ${approverName} ${approvalDate ? `${deniedPopoverPart3} ${approvalDate}` : ''}.${deniedPopoverPart4}`.replace(/\s+/g, ' ');
      } else {
        return '';
      }
    };

    return {
      props,
      modalStore,
      formatDate,
      formatTimeRange,
      isEventLive,
      randomBoolean,
      approvalText,
      t,
    };
  }
};
</script>

<template>
  <div v-if="props.chapter && props.member" class="block mb-15 rounded-lg bg-white p-3 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
    <div class="flex justify-between items-center">
      <h5 class="text-xl font-weight-600 leading-tight text-primary-color dark:text-neutral-50">
        {{ props.chapter.organization.name }}
      </h5>
      <button
        type="button"
        class="font-weight-500"
        data-te-toggle="popover"
        data-te-placement="left"
        data-te-trigger="focus"
        :data-te-content="approvalText()"
      >
        <div v-if="props.member.status === 'Approved'" class="approved-role pill">
          <span>{{ props.member.role }}</span>
        </div>
        <div v-else :class="props.member.status.toLowerCase() + ' pill'">
          <span>{{ props.member.status }}</span>
        </div>
      </button>
    </div>
    <p class="mb-1 text-lg font-weight-500 leading-tight text-primary-color dark:text-neutral-50">
      {{ props.chapter.name }}
    </p>
    <p v-if="props.member.team" class="mb-2 text-md font-weight-500 leading-tight text-primary-color dark:text-neutral-50">
      {{ props.member.team.name }}
    </p>
    <div v-if="props.votingEvent" :class="isEventLive(props.votingEvent.start_time, props.votingEvent.end_time) ? 'event-container live' : 'event-container'">
      <div class="icon-container">
        <img v-if="isEventLive(props.votingEvent.start_time, props.votingEvent.end_time)" src="@/assets/icons/ballot_FILL0_wght400_GRAD0_opsz24.svg" class="icon" alt="Voting Icon" />
        <img v-else src="@/assets/icons/calendar_month_FILL0_wght400_GRAD0_opsz24.svg" class="icon" alt="Calendar Icon" />
      </div>
      <div class="text-container">
        <div :class="`flex justify-between items-center ${isEventLive(props.votingEvent.start_time, props.votingEvent.end_time) ? 'mb-1' : ''}`">
          <h5 :class="`font-weight-600 leading-tight text-primary-color dark:text-neutral-50 ${isEventLive(props.votingEvent.start_time, props.votingEvent.end_time) ? 'text-xl' : 'text-lg mb-1'}`">
            {{ isEventLive(props.votingEvent.start_time, props.votingEvent.end_time) ? t('dashboard.charity_card.live_event') : t('dashboard.charity_card.upcoming_event') }}
          </h5>
          <div v-if="isEventLive(props.votingEvent.start_time, props.votingEvent.end_time)" class="live pill flex justify-between items-center">
            <div class="live-circle"></div>
            <span class="ml-1">{{ t('dashboard.charity_card.live') }}</span>
          </div>
        </div>
        <p class="mb-1 text-sm font-weight-500 leading-tight text-primary-color dark:text-neutral-50">
          {{ formatDate(props.votingEvent.start_time) }}
        </p>
        <p class="text-sm font-weight-500 leading-tight text-primary-color dark:text-neutral-50">
          {{ formatTimeRange(props.votingEvent.start_time, props.votingEvent.end_time) }}
        </p>
      </div>
    </div>
    <div v-else class="event-container">
      <div class="icon-container">
        <img src="@/assets/icons/release_alert_FILL0_wght400_GRAD0_opsz24.svg" class="icon" alt="Calendar Icon" />
      </div>
      <div class="text-container">
        <div class="flex justify-between items-center">
          <h5 class="font-weight-600 leading-tight text-primary-color dark:text-neutral-50 text-lg mb-1">
            {{ t('dashboard.charity_card.no_scheduled_events') }}
          </h5>
        </div>
        <p class="mb-1 text-sm font-weight-500 leading-tight text-primary-color dark:text-neutral-50">
          {{ t('dashboard.charity_card.no_scheduled_events_sub') }}
        </p>
      </div>
    </div>
    <div class="flex justify-end items-end">
      <button v-if="props.votingEvent" type="button" class="secondary-button mt-5" @click="modalStore.setModal('event_details', props.votingEvent)">
        {{ t('dashboard.charity_card.view_details') }}
      </button>
      <router-link v-if="props.votingEvent" :to="'/voting/' + props.chapter.unique_path">
        <button type="button" class="primary-button mt-5 ml-5">
          {{ isEventLive(props.votingEvent.start_time, props.votingEvent.end_time) ? t('dashboard.charity_card.enter_event') : t('dashboard.charity_card.view_charities') }}
        </button>
      </router-link>
    </div>
  </div>
</template>

<style scoped>
</style>
