import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, uploadApi } from "@/javascript/lib/api.service.js";

export const useCharitiesStore = defineStore('charities', () => {
  const charities = ref(null);
  const lastParams = ref(null);
  const allCharities = ref([]);

  const getCharities = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && charities.value) {
        return charities.value;
      }
      let url = '/charities';
      charities.value = await fetchData(url, params);
      lastParams.value = params;
      return charities.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getAllCharitiesForChapter = async (chapterId, searchTerm) => {
    try {
      let url = '/charities';
      let params = new URLSearchParams();
      params.set('query[s]', 'name ASC');
      params.set('query[chapter_id_eq]', chapterId);
      params.set('query[archived_eq]', 'false');

      if (searchTerm) {
        params.set('query[name_cont]', searchTerm);
      }

      allCharities.value = await fetchData(url, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const newCharity = async (data) => {
    try {
      let url = '/charities';
      const response = await uploadApi(url, data, true);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const updateCharity = async (data = null, id = null) => {
    try {
      let url = `/charities/${id}`;
      const response = await uploadApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return {
    charities,
    allCharities,
    getCharities,
    getAllCharitiesForChapter,
    newCharity,
    updateCharity,
  };
});
