import moment from 'moment';
import 'moment-timezone';

export function formatDate(datestamp) {
  return moment.utc(datestamp).tz('America/New_York').format('dddd, MMMM Do YYYY');
}

export function formatTimeRange(startTime, endTime) {
  return moment.utc(startTime).tz('America/New_York').format('hh:mm A') + ' - ' + moment.utc(endTime).tz('America/New_York').format('hh:mm A');
}

export function isEventLive(startTime, endTime) {
  const currentTime = moment();
  return currentTime.isBetween(moment.utc(startTime).tz('America/New_York'), moment.utc(endTime).tz('America/New_York'));
}

export function isEventInPast(dateTime) {
  return moment.utc(dateTime).tz('America/New_York').isBefore(moment());
}

export function formatDatePicker(dateTime) {
  return moment(dateTime).format('YYYY-MM-DDTHH:mm');
}

export function convertToUTC(dateTime) {
  const localDate = moment(dateTime);
  const utcDate = localDate.utc(); // Convert to UTC
  return utcDate.toISOString(); // Return ISO string in UTC
}


