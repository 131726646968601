import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, createApi } from "@/javascript/lib/api.service.js";

export const useVotesStore = defineStore('votes', () => {
  const votes = ref(null);
  const lastParams = ref(null);

  const getVotes = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && votes.value) {
        return votes.value;
      }
      let url = '/votes';
      votes.value = await fetchData(url, params);
      lastParams.value = params;
      return votes.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const newVote = async (data) => {
    try {
      let url = '/votes';
      const response = await createApi(url, data);

      if (response.json?.data) {
       return await getVotes();
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  return {
    votes,
    getVotes,
    newVote,
  };
});
