<script>
import {onMounted, ref} from "vue";
import { useI18n } from "vue-i18n";
import { deleteApi } from "@/javascript/lib/api.service.js";
import {
  Collapse,
  Dropdown,
  initTE,
} from "tw-elements";

import { useVotingStore } from '@/javascript/stores/voting.js';
import { useMembersStore } from '@/javascript/stores/members.js';
import { useChaptersStore } from '@/javascript/stores/chapters.js';
import { useAuthenticationStore } from "@/javascript/stores/authentication.js";
import { useModalStore } from "@/javascript/stores/modal.js";

import router from "../../router/router.js";

export default {
  setup() {
    // Stores
    const votingStore = useVotingStore();
    const membersStore = useMembersStore();
    const chaptersStore = useChaptersStore();
    const authenticationStore = useAuthenticationStore();
    const modalStore = useModalStore();

    // Variables
    const isLoading = ref(true);

    onMounted(async () => {
      // Initialize TW Elements
      initTE({Collapse, Dropdown}, {allowReinits: true});

      await authenticationStore.loadUserData(document.body.dataset.currentUserId);
      
      let chapterParams = new URLSearchParams();
      chapterParams.set('query[s]', 'name ASC');
      chapterParams.set('query[archived_eq]', 'false');

      let memberParams = new URLSearchParams();
      memberParams.set('query[user_id_eq]', document.body.dataset.currentUserId);
      memberParams.set('query[archived_eq]', 'false');

      try {
        await Promise.all([
          chaptersStore.getChapters(chapterParams),
          membersStore.getMembers(memberParams),
        ]);

        isLoading.value = false;
      } catch (error) {
        console.error(error);
      }
    });

    // I18n
    const { t } = useI18n();

    const toggleDropdownMenu = () => {
      // Clear out chapterSelection from VotingStore to ensure header title goes back to default
      votingStore.setChapterSelection(null);
      var button = document.getElementById('menu');
      button.click();
    };

    const navigateManageChapter = (chapterId) => {
      let chapter = getChapterFromId(chapterId);
      router.push({ path: `/manage/${chapter.unique_path}`, props: { id: chapter.unique_path } });
      toggleDropdownMenu();
    };

    const signOut = async () => {
      try {
        // Send sign-out request to Rails backend
        await deleteApi('/users/sign_out')
          .then(() => {
            authenticationStore.clearUserData();
          });
      } catch (error) {
        if (error.response && error.response.status === 200) {
          // Empty response is expected for successful sign-out
          // Handle successful sign-out (e.g., update UI)
          console.log(error);
        } else {
          console.error('Error signing out:', error);
        }
      }
    };

    const getOrganizerMemberships = () => {
      return membersStore.getOrganizerMemberships(document.body.dataset.currentUserId);
    };

    const getChapterFromId = (id) => {
      return chaptersStore.getChapterFromId(id);
    };

    return {
      t,
      isLoading,
      authenticationStore,
      votingStore,
      modalStore,
      toggleDropdownMenu,
      signOut,
      getOrganizerMemberships,
      navigateManageChapter,
      getChapterFromId,
    };
  }
};
</script>

<template>
  <nav
    class="fixed flex-no-wrap flex w-full items-center justify-between nav-background py-2 shadow-md shadow-black/5 dark:bg-neutral-600 dark:shadow-black/ justify-start">
    <div class="flex w-full flex-wrap items-center justify-between px-3">
      <!-- Hamburger button for mobile view -->
      <button
        class="block border-0 bg-transparent px-2 text-primary-nav hover:no-underline hover:shadow-none focus:no-underline focus:shadow-none focus:outline-none focus:ring-0 dark:text-neutral-200"
        type="button"
        id="menu"
        data-te-collapse-init
        data-te-target="#navbarToggleExternalContent"
        aria-controls="navbarToggleExternalContent"
        aria-expanded="false"
        aria-label="Toggle navigation">
        <!-- Hamburger icon -->
        <span class="[&>svg]:w-7">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="h-7 w-7">
            <path
              fill-rule="evenodd"
              d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm0 5.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              clip-rule="evenodd" />
          </svg>
        </span>
      </button>

      <!-- Collapsible navigation container -->
      <div
        class=" flex-grow basis-[100%] items-center flex basis-auto"
        id="navbarSupportedContent1"
        data-te-collapse-item>
        <!-- Logo -->
        <a
          class="mr-2 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:text-neutral-400 lg:mb-2 lg:mt-2 mb-1 mt-1"
          href="/dashboard">
          <img
            src="@/assets/Citadel_Logomark_Black.png"
            style="height: 25px"
            alt="Citadel Logomark"
            loading="lazy" />
        </a>
        <!-- Left navigation links -->
        <ul
          class="list-style-none mr-auto flex flex-col pl-0 lg:flex-row"
          data-te-navbar-nav-ref>
          <!-- Team link -->
          <li :class="`${votingStore.chapterSelection ? '' : 'mb-1 mt-1'} pr-2 container flex flex-col`" data-te-nav-item-ref>
            <span
              v-if="votingStore.chapterSelection"
              class="text-primary-nav nav-link-organization transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
              data-te-nav-link-ref
            >{{ votingStore.chapterSelection.organization.name }}</span>
            <span
              v-if="votingStore.chapterSelection"
              class="text-primary-nav nav-link-chapter transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
              data-te-nav-link-ref
            >{{ votingStore.chapterSelection.name }}</span>
            <span
              v-else
              class="text-primary-nav nav-link transition duration-200 hover:text-neutral-700 hover:ease-in-out focus:text-neutral-700 disabled:text-black/30 motion-reduce:transition-none dark:text-neutral-200 dark:hover:text-neutral-300 dark:focus:text-neutral-300 lg:px-2 [&.active]:text-black/90 dark:[&.active]:text-neutral-400"
              data-te-nav-link-ref
            >{{ t('header.app_title') }}</span>
          </li>
        </ul>
      </div>

      <div class="relative flex items-center">
        <div
          class="relative"
          data-te-dropdown-ref
          data-te-dropdown-alignment="end">
          <!-- Second dropdown trigger -->
          <a
            class="hidden-arrow flex items-center whitespace-nowrap transition duration-150 ease-in-out motion-reduce:transition-none"
            href="#"
            id="dropdownMenuButton2"
            role="button"
            data-te-dropdown-toggle-ref
            aria-expanded="false">
            <!-- User avatar -->
            <img
              v-if="authenticationStore.currentUser && authenticationStore.currentUser.image_url"
              :src="authenticationStore.currentUser.image_url"
              class="rounded-full object-cover"
              style="height: 30px; width: 30px"
              alt=""
              loading="lazy"
            />
            <img
              v-else
              src="@/assets/icons/account_circle_FILL0_wght400_GRAD0_opsz24.svg"
              class="rounded-full"
              style="height: 30px; width: 30px"
              alt=""
              loading="lazy"
            />
          </a>
          <!-- Second dropdown menu -->
          <ul
            class="absolute z-[1000] float-left m-0 hidden min-w-max list-none overflow-hidden rounded-lg border-none bg-white bg-clip-padding text-left text-base shadow-lg dark:bg-neutral-700 [&[data-te-dropdown-show]]:block"
            aria-labelledby="dropdownMenuButton2"
            data-te-dropdown-menu-ref>
            <!-- Second dropdown menu items -->
            <li>
              <a
                class="block w-full cursor-pointer whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                @click="signOut"
                data-te-dropdown-item-ref
              >{{ t('header.sign_out') }}</a>
            </li>
            <li v-if="authenticationStore.currentUser">
              <a
                @click="modalStore.setModal('edit_profile', authenticationStore.currentUser)"
                class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                href="#"
                data-te-dropdown-item-ref
              >{{ t('header.edit_profile') }}</a>
            </li>
            <li v-if="authenticationStore.currentUser && authenticationStore.currentUser.role === 'Admin'">
              <a
                class="block w-full whitespace-nowrap bg-transparent px-4 py-2 text-sm font-normal text-neutral-700 hover:bg-neutral-100 active:text-neutral-800 active:no-underline disabled:pointer-events-none disabled:bg-transparent disabled:text-neutral-400 dark:text-neutral-200 dark:hover:bg-white/30"
                :href="authenticationStore.currentUser.admin_url"
                data-te-dropdown-item-ref
              >{{ t('header.admin_panel') }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <div
    id="navbarToggleExternalContent"
    class="!visible hidden menu-dropdown"
    data-te-collapse-item
  >
    <div
      v-if="!isLoading"
      class="block nav-menu-background px-6 py-4 dark:bg-neutral-600 dark:text-neutral-50">
      <router-link to="/dashboard" @click="toggleDropdownMenu">
        <span class="text-primary-nav nav-menu-link">{{ t('header.dashboard') }}</span>
      </router-link>
      <div v-for="(member, index) in getOrganizerMemberships()" :key="index">
        <div class="h-2" />
        <router-link to="/dashboard" @click="navigateManageChapter(member.memberable_id)">
          <span class="text-primary-nav nav-menu-link">{{ t('header.manage') }} {{ getChapterFromId(member.memberable_id).name }}</span>
        </router-link>
      </div>
<!--      <span class="text-primary-nav nav-menu-link">{{ t('header.charities') }}</span>-->
<!--      <div class="h-2" />-->
<!--      <span class="text-primary-nav nav-menu-link">{{ t('header.past_votes') }}</span>-->
      <div class="h-2" />
      <div class="flex flex-col items-center border-t border-gray-400">
        <p class="text-xs mt-2">{{ t('copyright.a_citadel_product') }}</p>
        <img
          src="@/assets/Citadel_Full_Logo_Horizontal.png"
          class="mt-2 w-32"
          alt="Citadel Logo Full"
          loading="lazy" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
