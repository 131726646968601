import {ref} from 'vue';
import {defineStore} from 'pinia';
import {fetchData, updateApi, createApi} from "@/javascript/lib/api.service.js";

export const useMembersStore = defineStore('members', () => {
  const members = ref(null);
  const allMembers = ref([]);
  const approvedMembers = ref([]);
  const pendingMembers = ref([]);
  const lastParams = ref(null);

  const getMembers = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && members.value) {
        return members.value;
      }
      let url = '/members';
      members.value = await fetchData(url, params);
      lastParams.value = params;
      return members.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const createMember = async (data = null) => {
    try {
      let url = `/members`;
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const updateMember = async (data = null) => {
    try {
      let url = `/members/${data.id}`;
      const response = await updateApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getAllMembersForChapter = async (chapterId, searchTerm) => {
    try {
      let url = '/members';
      let params = new URLSearchParams();
      params.set('query[s]', 'user_first_name ASC');
      params.set('query[memberable_type_eq]', 'Chapter');
      params.set('query[memberable_id_eq]', chapterId);

      if (searchTerm) {
        params.set('query[user_first_name_or_user_last_name_or_user_email_or_role_or_status_cont]', searchTerm);
      }

      allMembers.value = await fetchData(url, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getApprovedMembersForChapter = async (chapterId) => {
    try {
      let url = '/members';
      let params = new URLSearchParams();
      params.set('query[s]', 'user_first_name ASC');
      params.set('query[status_eq]', 'Approved');
      params.set('query[memberable_type_eq]', 'Chapter');
      params.set('query[memberable_id_eq]', chapterId);
      approvedMembers.value = await fetchData(url, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getPendingMembersForChapter = async (chapterId) => {
    try {
      let url = '/members';
      let params = new URLSearchParams();
      params.set('query[s]', 'user_first_name ASC');
      params.set('query[status_eq]', 'Pending');
      params.set('query[memberable_type_eq]', 'Chapter');
      params.set('query[memberable_id_eq]', chapterId);
      pendingMembers.value = await fetchData(url, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const newMember = async (data) => {
    try {
      let url = '/members';
      const response = await createApi(url, data);

      if (response.json?.data) {
        return await getMembers();
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getMemberByChapterId = (chapterId, userId) => {
    return members.value.find((member) => member.memberable_type === 'Chapter' && member.memberable_id === chapterId && member.user.id == userId);
  };

  const getOrganizerMemberships = (userId) => {
    return members.value.filter((member) => member.memberable_type === 'Chapter' && member.role === "Organizer" && member.user.id == userId);
  };

  const getApprovedMemberByUserId = (userId) => {
    return approvedMembers.value.find((member) => member.user.id === userId);
  };

  const getPendingMemberByUserId = (userId) => {
    return pendingMembers.value.find((member) => member.user.id === userId);
  };

  const getApprovedMembers = () => {
    return approvedMembers.value;
  };

  const getPendingMembers = () => {
    return pendingMembers.value;
  };

  return {
    members,
    allMembers,
    approvedMembers,
    pendingMembers,
    getMembers,
    createMember,
    updateMember,
    getAllMembersForChapter,
    getApprovedMembersForChapter,
    getPendingMembersForChapter,
    newMember,
    getApprovedMemberByUserId,
    getOrganizerMemberships,
    getPendingMemberByUserId,
    getMemberByChapterId,
    getApprovedMembers,
    getPendingMembers,
  };
});
