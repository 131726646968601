import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, createApi, uploadApi, getApi, updateApi} from "@/javascript/lib/api.service.js";

export const useUsersStore = defineStore('users', () => {
  const users = ref(null);
  const lastParams = ref(null);

  const getUsers = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && users.value) {
        return users.value;
      }
      let url = '/users';
      users.value = await fetchData(url, params);
      lastParams.value = params;
      return users.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const createUser = async (data = null) => {
    try {
      let url = `/users`;
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getUser = async (id) => {
    try {
      let url = `/users/${id}`;
      const response = await getApi(url);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  };


  const updateUser = async (data = null, id = null) => {
    try {
      let url = `/users/${id}`;
      const response = await uploadApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const inviteUser = async (data = null) => {
    try {
      let url = '/users/invite_user';
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const updateUserMember = async (data = null, id = null) => {
    try {
      let url = `/users/${id}`;
      const response = await updateApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getUserFromId = (id) => {
    return users.value.find((user) => user.id === id);
  };

  return {
    users,
    createUser,
    updateUser,
    inviteUser,
    updateUserMember,
    getUsers,
    getUser,
    getUserFromId,
  };
});
