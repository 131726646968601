import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, createApi, updateApi } from "@/javascript/lib/api.service.js";

export const useTeamsStore = defineStore('teams', () => {
  const teams = ref(null);
  const lastParams = ref(null);
  const allTeams = ref([]);

  const getTeams = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && teams.value) {
        return teams.value;
      }
      let url = '/teams';
      teams.value = await fetchData(url, params);
      lastParams.value = params;
      return teams.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getAllTeamsForChapter = async (chapterId, searchTerm) => {
    try {
      let url = '/teams';
      let params = new URLSearchParams();
      params.set('query[s]', 'name ASC');
      params.set('query[chapter_id_eq]', chapterId);
      params.set('query[archived_eq]', 'false');

      if (searchTerm) {
        params.set('query[name_cont]', searchTerm);
      }

      allTeams.value = await fetchData(url, params);
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const newTeam = async (data) => {
    try {
      let url = '/teams';
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const updateTeam = async (data = null) => {
    try {
      let url = `/teams/${data.id}`;
      const response = await updateApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getTeamFromPath = (path) => {
    return teams.value.find((team) => team.unique_path === path);
  };

  const getTeamFromId = (id) => {
    return teams.value.find((team) => team.id === id);
  };

  return {
    teams,
    allTeams,
    getAllTeamsForChapter,
    getTeams,
    newTeam,
    updateTeam,
    getTeamFromPath,
    getTeamFromId,
  };
});
