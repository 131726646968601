import { ref } from 'vue';
import { defineStore } from 'pinia';
import { fetchData, createApi, deleteApi } from "@/javascript/lib/api.service.js";

export const useVotingEventCharitiesStore = defineStore('votingEventCharities', () => {
  const votingEventCharities = ref(null);
  const lastParams = ref(null);

  const getVotingEventCharities = async (params = null, override = false) => {
    try {
      if (!override && (params === null || params === lastParams.value) && votingEventCharities.value) {
        return votingEventCharities.value;
      }
      let url = '/voting_event_charities';
      votingEventCharities.value = await fetchData(url, params);
      lastParams.value = params;
      return votingEventCharities.value;
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const createVotingEventCharity = async (data = null) => {
    try {
      let url = `/voting_event_charities`;
      const response = await createApi(url, data);

      if (response.json) {
        return response.json;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const deleteVotingEventCharity = async (id = null) => {
    try {
      let url = `/voting_event_charities/${id}`;
      const response = await deleteApi(url);

      if (response) {
        return response;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
  };

  const getVotingEventCharitiesByVotingEventId = (votingEventId) => {
    return votingEventCharities.value.filter((votingEventCharity) => votingEventCharity.voting_event_id === votingEventId);
  };

  return {
    votingEventCharities,
    getVotingEventCharities,
    createVotingEventCharity,
    deleteVotingEventCharity,
    getVotingEventCharitiesByVotingEventId,
  };
});
