import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useVotingStore = defineStore('voting', () => {
  const chapterSelection = ref(null);
  const charitySelection = ref(null);

  const setChapterSelection = (chapter) => {
    chapterSelection.value = chapter;
  };

  const setCharitySelection = (charity) => {
    if (charitySelection.value === charity) {
      charitySelection.value = null;
    } else {
      charitySelection.value = charity;
    }
  };

  return {
    chapterSelection,
    charitySelection,
    setChapterSelection,
    setCharitySelection,
  };
});
