export const en = {
  header: {
    app_title: "Citadel Voting",
    dashboard: "Dashboard",
    charities: "Charities",
    manage: "Manage",
    past_votes: "Past Votes",
    admin_panel: "Admin Panel",
    sign_out: "Sign Out",
    edit_profile: "Edit Profile",
  },
  dashboard: {
    chapters_belong_to: "Chapters You Belong To",
    welcome: "Welcome to Citadel Voting",
    search_chapters: "Search for your local Chapter below.",
    search_chapter_placeholder: "Chapter Name",
    join: "Join",
    team_belong_to: "Team {team_name}",
    charity_card: {
      no_scheduled_events: "No Scheduled Events",
      no_scheduled_events_sub: "Check back soon for details on the next event.",
      upcoming_event: "Upcoming Event",
      live_event: "LIVE Event",
      live: "LIVE",
      view_details: "View Event Details",
      enter_event: "Enter Event",
      view_charities: "View Charities",
      generic_popover_1: " by ",
      generic_popover_2: " on ",
      confirmation_popover_1: "You were approved as a ",
      pending_popover: "You are pending membership approval from an Organizer for ",
      denied_popover_1: "You were denied as a ",
      denied_popover_2: " If you believe this was a mistake please reach out to an Organizer.",
    },
  },
  voting: {
    voting_details: "Click on a charity from the list below to view more details, then, once you've made your decision, select the rectangular checkbox on the left-hand side and click 'Confirm Vote'.",
    voting_details_team: "Please note that you are apart of the {team} team, teams are only permitted to submit 1 vote on behalf of all team members.",
    voting_details_denied: "An Organizer has denied your membership to this organization's events. You will not be able to participate in voting. If you believe this is was an error, please contact an Organizer.",
    voting_details_voted: "Thank you for submitting your vote for this event.",
    voting_details_voted_team: "A member from your team {user_name} has already submitted a vote.",
    charities: "Charities",
    voted: "Voted",
    team_voted: "Team Voted",
    confirm_vote: "Confirm Vote",
    back: "Back",
    results: "Results",
    no_charites: "No Charities Selected",
    no_charities_text: "No charities have been selected for this event, please check back later."
  },
  voting_results: {
    approved_results: "Approved Member Votes",
    pending_results: "Pending Member Votes",
    pending_results_none: "No Pending Member Votes",
    enter_manual_vote: "Enter Manual Vote",
    deny: "Disable",
    approve: "Approve",
    refreshing_data: "Refreshing Data",
    manual_vote: "Manual Vote",
  },
  modal: {
    confirm_vote: {
      title: "Confirm Vote",
      body_1: "You selected ",
      body_2: " please confirm your vote.",
      negative: "Cancel",
      positive: "Confirm",
    },
    approve_member: {
      title: "Confirm Approval",
      body_1: "You're approving ",
      body_2: " as a Voter, please confirm.",
      negative: "Cancel",
      positive: "Confirm",
    },
    deny_member: {
      title: "Confirm Denial",
      body_1: "You're denying ",
      body_2: " as a Voter. Their vote will not count towards events, please confirm.",
      negative: "Cancel",
      positive: "Confirm",
    },
    manual_vote: {
      title: "Enter Manual Vote",
      body_1: "Enter voters name, and select the charity from the dropdown below.",
      negative: "Cancel",
      positive: "Confirm Vote",
      name_placeholder: "Name",
      select_charity: "Select Charity",
    },
    approved_votes: {
      title: "Approved Votes",
      body_1: "Below is the list of users who voted for ",
      negative: "Close",
    },
    event_details: {
      title: "Event Details",
      negative: "Close",
      address: "Address",
      date: "Date",
      time: "Time",
      additional_info: "Additional Info",
    },
    new_member: {
      title: "New Member Form",
      body_1: "Fill out the form below to add a new member. The member will receive an email with login instructions and be able to vote in all future events. ",
      negative: "Cancel",
      positive: "Submit",
      user_found: "Existing User Found",
      user_found_text: "A user under the email {email} was found. Their information will be pre-populated into the form and currently remain non-editable, you may still fill out the rest of the form and add them as a member.",
      form: {
        first_name_placeholder: "First Name",
        last_name_placeholder: "Last Name",
        email_placeholder: "Email",
        employer_name_placeholder: "Employer Name",
        address_placeholder: "Address",
        city_placeholder: "City",
        postal_code_placeholder: "Postal Code",
        phone_number_placeholder: "Phone Number",
        select_role: "Select a Membership Role",
        role_placeholder: "Role",
        voter_role: "Voter",
        organizer_role: "Organizer",
        team_placeholder: "Team (Optional)",
        select_team: "Select a Team (Optional)",
      }
    },
    edit_member: {
      title: "Edit Member Form",
      body: "Use the form below to update member details.",
      negative: "Cancel",
      positive: "Submit",
    },
    disable_member: {
      title: "Disable Member",
      body_1: "You're disabling ",
      body_2: " as a Voter. They will not be permitted to vote in future events, please confirm.",
      negative: "Cancel",
      positive: "Confirm",
    },
    new_event: {
      title: "New Event Form",
      body: "Fill out the form below to setup a new event.",
      negative: "Cancel",
      positive: "Submit",
      form: {
        start_date_placeholder: "Start Date & Time (UTC)",
        end_date_placeholder: "End Date & Time (UTC)",
        address_placeholder: "Address",
        city_placeholder: "City",
        postal_code_placeholder: "Postal Code",
        description_label: "Description (Optional)",
        description_placeholder: "Description",
      }
    },
    edit_event: {
      title: "Edit Event Form",
      body: "Use the form below to update event details.",
      negative: "Cancel",
      positive: "Submit",
    },
    cancel_event: {
      title: "Confirm Cancellation",
      body_1: "You're cancelling an event scheduled for ",
      body_2: ". To cancel this event, please confirm below.",
      negative: "Cancel",
      positive: "Confirm",
    },
    new_charity: {
      title: "New Charity Form",
      body: "Fill out the form below to add a new charity to your chapter.",
      negative: "Cancel",
      positive: "Submit",
      form: {
        name_placeholder: "Name",
        address_placeholder: "Address",
        city_placeholder: "City",
        description_label: "Description (Optional)",
        description_placeholder: "Description",
        video_url_label: "Video URL (YouTube)",
        video_url_placeholder: "Video URL",
        image_label: "Charity Image",
        image_placeholder: "Upload Image Here",
      }
    },
    edit_charity: {
      title: "Edit Charity Form",
      body: "Use the form below to update charity details.",
      negative: "Cancel",
      positive: "Submit",
    },
    cancel_charity: {
      title: "Confirm Deletion",
      body_1: "You're deleting ",
      body_2: ". To delete this charity from your chapter, please confirm below.",
      negative: "Cancel",
      positive: "Confirm",
    },
    new_team: {
      title: "New Team Form",
      body: "Fill out the form below to add a new team to your chapter.",
      negative: "Cancel",
      positive: "Submit",
      form: {
        name_placeholder: "Name",
      }
    },
    edit_team: {
      title: "Edit Team Form",
      body: "Use the form below to update team details.",
      negative: "Cancel",
      positive: "Submit",
    },
    cancel_team: {
      title: "Confirm Deletion",
      body_1: "You're deleting ",
      body_2: ". To delete this team from your chapter, please confirm below.",
      negative: "Cancel",
      positive: "Confirm",
    },
    edit_profile: {
      title: "Edit Profile",
      body_1: "",
      negative: "Close",
      positive: "Submit",
      image_label: "Profile Image",
      image_placeholder: "Upload Image Here",
      notice: "Notice",
      disclaimer: "Please reach out to an Organizer regarding any updates to your profile, with the exception of the profile image."
    },
  },
  manage: {
    chapter: {
      members: "Members",
      other_members: "Other Members",
      approved_members: "Approved Members",
      events: "Events",
      charities: "Charities",
      teams: "Teams",
      add_member: "Add a Member",
      add_event: "Add an Event",
      add_charity: "Add a Charity",
      add_team: "Add a Team",
      edit_member: "Edit",
      view_member: "View",
      showing: "Showing",
      search_placeholder: "Search...",
      members_section: {
        employer_name: "Employer Name",
        address: "Address",
        city: "City",
        postal_code: "Postal Code",
        phone_number: "Phone Number",
        team: "Team",
        approve_member: "Approve",
        disable_member: "Disable",
        edit_member: "Edit",
      },
      events_section: {
        past_event: "Past Event",
        upcoming_event: "Upcoming Event",
        charities: "Charities",
        description: "Description",
        edit: "Edit",
        no_charities: "No charities selected.",
        cancel_event: "Cancel Event",
        winner: "Winner",
        votes: "Vote(s)",
      },
      charities_section: {
        video: "Video",
        image: "Image",
        edit: "Edit",
        delete_charity: "Delete Charity",
      },
      teams_section: {
        notice: "Notice",
        disclaimer: "To reassign members to and from a team, please navigate to the 'Members' tab and edit the individual member's team.",
        members: "Members",
        current_members: "Member(s)",
        edit: "Edit",
        delete_team: "Delete Team",
      }
    }
  },
  copyright: {
    a_citadel_product: "A product by Citadel Technology Group Inc."
  },
};
