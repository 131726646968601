<script>
import {onMounted, ref, watch} from "vue";
import { useI18n } from "vue-i18n";

// Stores
import { useTeamsStore } from '@/javascript/stores/teams.js';
import { useModalStore } from '@/javascript/stores/modal.js';
import { useMembersStore } from '@/javascript/stores/members.js';
import { debounce } from "lodash";

export default {
  props: {
    chapter: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    // Stores
    const teamsStore = useTeamsStore();
    const membersStore = useMembersStore();
    const modalStore = useModalStore();

    // Variables
    const isLoading = ref(true);
    const searchTerm = ref("");
    const members = ref([]);

    // I18n
    const { t } = useI18n();

    onMounted(() => {
      getAllTeamsForChapter(props.chapter.id);
      getMembers(props.chapter.id);
    });

    const getAllTeamsForChapter = async (chapterId, search = '') => {
      await teamsStore.getAllTeamsForChapter(chapterId, search);
    };

    const debouncedSearch = debounce((search) => {
      if (search) {
        getAllTeamsForChapter(props.chapter.id, search);
      } else {
        getAllTeamsForChapter(props.chapter.id, '');
      }
    }, 250);

    const getMembers = async (chapterId) => {
      // Get all relevant members to the current chapter
      let params = new URLSearchParams();
      params.set('query[s]', 'name ASC');
      params.set('query[archived_eq]', 'false');
      params.set('query[chapter_id_eq]', chapterId);
      params.set('query[team_id_null]', 0);

      members.value = await membersStore.getMembers(params);
      sortMembers();

      return true;
    };

    const sortMembers = () => {
      teamsStore.allTeams.forEach(team => {
        team.current_members = members.value.filter(member => member.team.id === team.id);
      });

      isLoading.value = false;
    };

    const createTeam = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let team = {
          name: modalStore.newTeam.name,
          chapter_id: props.chapter.id,
        };

        teamsStore.newTeam(team)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              resolve(response);
            }
          })
          .then(async () => {
            await getAllTeamsForChapter(props.chapter.id, '');
            await getMembers(props.chapter.id);
            modalStore.resetModal();
          })
          .catch(error => {
            isLoading.value = false;
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const editTeam = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let team = {
          id: modalStore.newTeam.id,
          name: modalStore.newTeam.name,
        };

        teamsStore.updateTeam(team)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              resolve(response);
            }
          })
          .then(async () => {
            await getAllTeamsForChapter(props.chapter.id, '');
            await getMembers(props.chapter.id);
            modalStore.resetModal();
          })
          .catch(error => {
            isLoading.value = false;
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const archiveTeam = () => {
      new Promise((resolve, reject) => {
        let team = modalStore.modalTypeExtraParam;
        team.archived = true;

        teamsStore.updateTeam(team)
          .then(async () => {
            await getAllTeamsForChapter(props.chapter.id, '');
            await getMembers(props.chapter.id);
            modalStore.resetModal();
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      })
      .then(async () => {
        await getAllTeamsForChapter(props.chapter.id);
      });
    };

    watch(searchTerm, debouncedSearch, { immediate: false });

    watch(() => modalStore.positiveHandleTrigger, (value) => {
      if (value) {
        switch (modalStore.modalType) {
          case 'new_team':
            createTeam();
            break;
          case 'edit_team':
            editTeam();
            break;
          case 'cancel_team':
            archiveTeam();
            break;
        }
      }
    });

    return {
      props,
      t,
      isLoading,
      searchTerm,
      teamsStore,
      modalStore,
    };
  }
};
</script>

<template>
  <div>
    <div v-if="props.chapter && !isLoading" class="overflow-x-auto m-3">
      <div class="flex justify-between items-center">
        <p class="text-lg font-weight-600 leading-tight text-primary-color dark:text-neutral-50">
          {{ t('header.manage') }} {{ t('manage.chapter.teams') }}
        </p>
        <button type="button" class="primary-button text-sm approve" @click="modalStore.setModal('new_team')">
          {{ t('manage.chapter.add_team') }}
        </button>
      </div>
      <div class="bg-orange-200 border border-orange-500 border-4 text-gray-800 px-4 py-2 rounded-lg my-2">
        <p class="font-bold text-sm mb-1">{{ t('manage.chapter.teams_section.notice') }}</p>
        <p class="text-xs">{{ t('manage.chapter.teams_section.disclaimer') }}</p>
      </div>
      <input type="text" v-model="searchTerm" :placeholder="t('manage.chapter.search_placeholder')" class="mt-2 w-full px-4 py-2 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">
      <p class="text-sm mb-1 mt-2">{{ t('manage.chapter.showing') }} {{ teamsStore.allTeams.length }} {{ t('manage.chapter.teams') }}</p>
      <div class="overflow-x-auto" id="accordion-wrapper">
        <div v-for="(team, accordionIndex) in teamsStore.allTeams" :key="team.id" class="border-t border-gray-300 w-auto">
          <button
            class="group w-full"
            type="button"
            data-te-collapse-init
            data-te-collapse-collapsed
            :data-te-target="`#collapse${accordionIndex}`"
            aria-expanded="false"
            :aria-controls="`collapse${accordionIndex}`">
            <div class="flex items-center">
              <div class="flex flex-col text-left p-2 truncate">
                <p class="flex text-sm font-medium mb-1">{{ team.name }}</p>
                <p class="text-sm">{{ team.current_members.length }} {{ t('manage.chapter.teams_section.current_members')}}</p>
              </div>
            </div>
          </button>
          <div
            :id="`collapse${accordionIndex}`"
            class="!visible hidden"
            data-te-collapse-item
            data-te-parent="#accordion-wrapper"
            :aria-labelledby="`heading${accordionIndex}`">
            <div class="px-2 pb-4 mt-1">
              <p class="flex items-center py-1 border-t border-gray-300 text-xs font-weight-600">{{ t('manage.chapter.teams_section.members') }}</p>
              <div v-for="(member, index) in team.current_members" :key="'member-' + index" class="flex items-center py-1 border-t border-gray-300">
                <p class="text-xs">{{ member.user.first_name }} {{ member.user.last_name }}</p>
              </div>
              <div class="flex justify-between items-center mt-2 py-1">
                <button type="button" class="primary-button text-sm deny" @click="modalStore.setModal('cancel_team', team)">
                  {{ t('manage.chapter.teams_section.delete_team') }}
                </button>
                <button type="button" class="primary-button text-sm edit" @click="modalStore.setModal('edit_team', team)">
                  {{ t('manage.chapter.events_section.edit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
