<script>
import {defineComponent} from "vue";
import HeaderComponent from "@/javascript/components/base/header-component.vue";
import BaseModal from "@/javascript/components/base-modal.vue";

export default defineComponent({
  components: { HeaderComponent, BaseModal }
});

</script>

<template>
  <div>
    <HeaderComponent />
    <router-view class="px-4 lg:px-8 pt-16 pb-4" />
    <BaseModal />
  </div>
</template>

<style lang="scss" scoped>
</style>
