<script>
import {computed, onMounted, ref, watch} from "vue";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash";

// Stores
import { useMembersStore } from '@/javascript/stores/members.js';
import { useUsersStore } from '@/javascript/stores/users.js';
import { useTeamsStore } from '@/javascript/stores/teams.js';
import { useModalStore } from '@/javascript/stores/modal.js';

export default {
  props: {
    chapter: {
      type: Object,
      required: true,
    },
  },

  setup(props) {
    // I18n
    const { t } = useI18n();

    // Stores
    const membersStore = useMembersStore();
    const userStore = useUsersStore();
    const teamsStore = useTeamsStore();
    const modalStore = useModalStore();

    // Variables
    const searchTerm = ref("");
    const isLoading = ref(true);

    // Computed Variables
    const approvedMemberCount = computed(() => membersStore.allMembers.filter((member) => { return member.status === 'Approved'; }).length);
    const unapprovedMemberCount = computed(() => membersStore.allMembers.filter((member) => { return member.status !== 'Approved'; }).length);

    onMounted(() => {
      if (props && props.chapter && props.chapter.id) {
        getAllMembersForChapter(props.chapter.id);
        getTeams(props.chapter.id);
      }
    });

    const getAllMembersForChapter = async (chapterId, search = '') => {
      await membersStore.getAllMembersForChapter(chapterId, search)
        .then(() => {
          isLoading.value = false;
        });
    };

    const getTeams = (chapterId) => {
      let teamParams = new URLSearchParams();
      teamParams.set('query[chapter_id_eq]', chapterId);
      teamParams.set('query[archived_eq]', 'false');

      teamsStore.getTeams(teamParams);
    };

    const debouncedSearch = debounce((search) => {
      if (search) {
        getAllMembersForChapter(props.chapter.id, search);
      } else {
        getAllMembersForChapter(props.chapter.id, '');
      }
    }, 250);

    const updateMember = (status) => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        let member = modalStore.modalTypeExtraParam;
        member.approver_id = document.body.dataset.currentUserId;
        member.status = status;

        membersStore.updateMember(member)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              resolve(response);
            }
          })
          .then(async () => {
            await debouncedSearch(searchTerm.value);
            modalStore.resetModal();
          })
          .catch(error => {
            isLoading.value = false;
            modalStore.setPositiveHandleTrigger(false);
            modalStore.convertErrorsToMessages(error);
          });
      });
    };

    const createMember = () => {
      isLoading.value = true;

      new Promise((resolve, reject) => {
        if (!modalStore.existingUser) {
          let password = "100Women";
          let user = {
            first_name: modalStore.newMember.first_name,
            last_name: modalStore.newMember.last_name,
            email: modalStore.newMember.email,
            phone_number: modalStore.newMember.phone_number,
            address: modalStore.newMember.address,
            city: modalStore.newMember.city,
            postal_code: modalStore.newMember.postal_code,
            employer_name: modalStore.newMember.employer_name,
            password: password,
            password_confirmation: password,
          };

          userStore.createUser({ user: user, organizer_creation: true })
            .then((response) => {
              if (response.errors && response.errors.length > 0) {
                reject(response.errors);
              } else {
                resolve(response);
              }
            })
            .catch(error => {
              isLoading.value = false;
              modalStore.setPositiveHandleTrigger(false);
              modalStore.convertErrorsToMessages(error);
            });
          // INVITE VIA EMAIL LOGIC - Maureen requested we manually set passwords for users currently, I'm very against this but
          // userStore.inviteUser({ user: user })
          //   .then((response) => {
          //     if (response.errors && response.errors.length > 0) {
          //       reject(response.errors);
          //     } else {
          //       resolve(response);
          //     }
          //   })
          //   .catch(error => {
          //     isLoading.value = false;
          //     modalStore.setPositiveHandleTrigger(false);
          //     modalStore.convertErrorsToMessages(error);
          //   });
        } else {
          resolve(modalStore.existingUser);
        }
      })
      .then(async (userResponse) => {
        let member = {
          approver_id: document.body.dataset.currentUserId,
          status: "Approved",
          role: modalStore.newMember.role,
          memberable_type: "Chapter",
          memberable_id: props.chapter.id,
          user_id: userResponse.id,
          team_id: modalStore.newMember.team_id === t('modal.new_member.form.select_team') ? null : modalStore.newMember.team_id,
        };

        return membersStore.createMember(member);
      })
      .then(async () => {
        await getAllMembersForChapter(props.chapter.id);
        modalStore.resetModal();
      })
      .catch(error => {
        isLoading.value = false;
        modalStore.setPositiveHandleTrigger(false);
        modalStore.convertErrorsToMessages(error);
      });
    };

    const editMember = () => {
      new Promise((resolve, reject) => {
        let user = {
          id: modalStore.newMember.user_id,
          first_name: modalStore.newMember.first_name,
          last_name: modalStore.newMember.last_name,
          email: modalStore.newMember.email,
          phone_number: modalStore.newMember.phone_number,
          address: modalStore.newMember.address,
          city: modalStore.newMember.city,
          postal_code: modalStore.newMember.postal_code,
          employer_name: modalStore.newMember.employer_name,
        };

        userStore.updateUserMember(user, user.id)
          .then((response) => {
            if (response.errors && response.errors.length > 0) {
              reject(response.errors);
            } else {
              resolve(response);
            }
          })
          .catch(error => {
            reject(error);
          });
      })
      .then(async () => {
        let member = {
          id: modalStore.newMember.member_id,
          role: modalStore.newMember.role,
          team_id: modalStore.newMember.team_id === t('modal.new_member.form.select_team') ? null : modalStore.newMember.team_id,
        };

        return membersStore.updateMember(member);
      })
      .then(async (response) => {
        await getAllMembersForChapter(props.chapter.id);
        modalStore.resetModal();
      })
      .catch(error => {
        modalStore.setPositiveHandleTrigger(false);
        modalStore.convertErrorsToMessages(error);
      });
    };

    watch(searchTerm, debouncedSearch, { immediate: false });

    watch(() => modalStore.positiveHandleTrigger, (value) => {
      if (value) {
        switch (modalStore.modalType) {
          case 'new_member':
            createMember();
            break;
          case 'edit_member':
            editMember();
            break;
          case 'approve_member':
            updateMember('Approved');
            break;
          case 'deny_member':
            updateMember('Denied');
            break;
        }
      }
    });

    return {
      props,
      t,
      isLoading,
      searchTerm,
      membersStore,
      modalStore,
      teamsStore,
      approvedMemberCount,
      unapprovedMemberCount,
    };
  }
};
</script>

<template>
  <div>
    <div v-if="props.chapter && !isLoading" class="overflow-x-auto m-3">
      <div class="flex justify-between items-center">
        <p class="text-lg font-weight-600 leading-tight text-primary-color dark:text-neutral-50">
          {{ t('header.manage') }} {{ t('manage.chapter.members') }}
        </p>
        <button type="button" class="primary-button text-sm approve" @click="modalStore.setModal('new_member')">
          {{ t('manage.chapter.add_member') }}
        </button>
      </div>
      <input type="text" v-model="searchTerm" :placeholder="t('manage.chapter.search_placeholder')" class="mt-2 w-full px-4 py-2 mb-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500">
      <p class="text-sm mb-1 ml-1">{{ t('manage.chapter.showing') }} {{ approvedMemberCount }} {{ t('manage.chapter.approved_members') }} <span class="italic text-xs">({{ unapprovedMemberCount }} {{ t('manage.chapter.other_members') }})</span></p>
      <div class="overflow-x-auto" id="accordion-wrapper">
        <div v-for="(member, accordionIndex) in membersStore.allMembers" :key="member.id" class="border-t border-gray-300 w-auto">
          <button
            class="group w-full"
            type="button"
            data-te-collapse-init
            data-te-collapse-collapsed
            :data-te-target="`#collapse${accordionIndex}`"
            aria-expanded="false"
            :aria-controls="`collapse${accordionIndex}`">
            <div class="flex justify-between items-center">
              <div class="flex items-center w-full overflow-hidden">
                <img
                  v-if="member.user.image_url"
                  :src="member.user.image_url"
                  class="rounded-full min-w-[110px] min-h-[110px] w-[110px] h-[110px] mt-2 object-cover"
                  alt=""
                  loading="lazy" />
                <img
                  v-else
                  src="@/assets/icons/account_circle_FILL0_wght400_GRAD0_opsz24.svg"
                  class="rounded-full min-w-[110px] min-h-[110px] w-[110px] h-[110px] mt-2"
                  alt=""
                  loading="lazy" />
                <div class="flex flex-col text-left pl-2 w-full overflow-hidden">
                  <p class="text-md font-semibold">{{ member.user.first_name }} {{member.user.last_name }}</p>
                  <p class="text-md responsive-text break-words">{{ member.user.email }}</p>
                  <p class="text-xs">{{ member.role }}</p>
                  <div class="flex flex-row">
                      <p class="text-xs">{{ member.status }}</p>
                      <div :class="`${member.status.toLowerCase()} status-indicator ml-1`"/>
                    </div>
                </div>
              </div>
              <!-- <div class="flex flex-col text-right w-1/4">
                <p class="text-xs">{{ member.role }}</p>
                <div class="flex justify-end">
                  <p class="text-xs">{{ member.status }}</p>
                  <div :class="`${member.status.toLowerCase()} status-indicator ml-1`"/>
                </div>
              </div> -->
            </div>
          </button>
          <div
            :id="`collapse${accordionIndex}`"
            class="!visible hidden"
            data-te-collapse-item
            data-te-parent="#accordion-wrapper"
            :aria-labelledby="`heading${accordionIndex}`">
            <div class="px-2 pb-4 mt-1">
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.employer_name') }}</p>
                <p class="text-xs">{{ member.user.employer_name }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.address') }}</p>
                <p class="text-xs">{{ member.user.address }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.city') }}</p>
                <p class="text-xs">{{ member.user.city }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.postal_code') }}</p>
                <p class="text-xs">{{ member.user.postal_code }}</p>
              </div>
              <div class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.phone_number') }}</p>
                <p class="text-xs">{{ member.user.phone_number }}</p>
              </div>
              <div v-if="member.team" class="flex justify-between items-center py-1 border-t border-gray-300">
                <p class="text-xs font-weight-600">{{ t('manage.chapter.members_section.team') }}</p>
                <p class="text-xs">{{ member.team.name }}</p>
              </div>
              <div class="flex justify-between items-center py-1 mt-2">
                <button v-if="member.status !== 'Approved'" type="button" class="primary-button text-sm approve" @click="modalStore.setModal('approve_member', member)">
                  {{ t('manage.chapter.members_section.approve_member') }}
                </button>
                <button v-if="member.status !== 'Denied'" type="button" class="primary-button text-sm deny" @click="modalStore.setModal('deny_member', member)">
                  {{ t('manage.chapter.members_section.disable_member') }}
                </button>
                <button type="button" class="primary-button text-sm edit" @click="modalStore.setModal('edit_member', member)">
                  {{ t('manage.chapter.members_section.edit_member') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.responsive-text {
  font-size: clamp(0.8rem, 2.5vw, 1rem);
}
</style>
