<script>
import {onMounted, ref, watch} from "vue";
import { useI18n } from "vue-i18n";
import { isEventLive } from "@/javascript/lib/date_helper.js";
import router from '@/javascript/router/router';

// Stores
import { useChaptersStore } from '@/javascript/stores/chapters.js';
import { useCharitiesStore } from '@/javascript/stores/charities.js';
import { useVotingEventsStore } from '@/javascript/stores/voting_events.js';
import { useVotingEventCharitiesStore } from '@/javascript/stores/voting_event_charities.js';
import { useMembersStore } from '@/javascript/stores/members.js';
import { useVotesStore } from '@/javascript/stores/votes.js';
import { useVotingStore } from '@/javascript/stores/voting.js';
import { useModalStore } from '@/javascript/stores/modal.js';

// Components
import CharityAccordion from "@/javascript/components/charity-accordion.vue";

export default {
  components: { CharityAccordion },
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    // I18n
    const { t } = useI18n();

    // Stores
    const chaptersStore = useChaptersStore();
    const charitiesStore = useCharitiesStore();
    const votingEventsStore = useVotingEventsStore();
    const votingEventCharitiesStore = useVotingEventCharitiesStore();
    const membersStore = useMembersStore();
    const votingStore = useVotingStore();
    const votesStore = useVotesStore();
    const modalStore = useModalStore();

    // Variables
    const isLoading = ref(true);
    const currentChapter = ref(null);
    const currentMember = ref(null);
    const currentVotingEvent = ref(null);
    const currentVotingEventCharities = ref(null);
    const currentCharities = ref(null);
    const currentVote = ref(null); // If user has pre-existing vote already
    const teamVote = ref(null); // If team member has already submitted a vote

    onMounted(async () => {
      await getVotingActivity();
    });

    const getChapterFromUniquePath = (path) => {
      return chaptersStore.getChapterFromPath(path);
    };

    const getMemberForChapter = (chapterId) => {
      return membersStore.getMemberByChapterId(chapterId, document.body.dataset.currentUserId);
    };

    const getVotingEventForChapter = (chapterId) => {
      return votingEventsStore.getVotingEventByChapterId(chapterId);
    };

    const getVotingActivity = async () => {
      let chapterParams = new URLSearchParams();
      chapterParams.set('query[archived_eq]', 'false');

      let memberParams = new URLSearchParams();
      memberParams.set('query[user_id_eq]', document.body.dataset.currentUserId);
      memberParams.set('query[archived_eq]', 'false');

      const estDate = new Date(new Date().getTime());
      let votingEventParams = new URLSearchParams();
      votingEventParams.set('query[end_time_gteq]', estDate);
      votingEventParams.set('query[s]', 'start_time ASC');
      votingEventParams.set('query[archived_eq]', 'false');

      try {
        await Promise.all([
          chaptersStore.getChapters(chapterParams),
          membersStore.getMembers(memberParams),
          votingEventsStore.getVotingEvents(votingEventParams),
        ]);

        currentChapter.value = getChapterFromUniquePath(props.id);
        if (currentChapter.value) {
          votingStore.setChapterSelection(currentChapter.value);
          currentVotingEvent.value = getVotingEventForChapter(currentChapter.value.id);
          currentMember.value = getMemberForChapter(currentChapter.value.id);

          if (currentVotingEvent.value && currentMember.value) {
            let params = new URLSearchParams();
            params.set('query[voting_event_id_eq]', currentVotingEvent.value.id);
            params.set('query[archived_eq]', 'false');

            // Get voting_event_charities based on the current voting_event
            currentVotingEventCharities.value = await votingEventCharitiesStore.getVotingEventCharities(params);

            if (currentVotingEventCharities.value.length > 0) {
              let params = new URLSearchParams();
              params.set('query[s]', 'name ASC');
              params.set('query[archived_eq]', 'false');
              currentVotingEventCharities.value.map(cvec => {
                params.append('query[id_in][]', cvec.charity_id);
              });

              // Get charities based on voting_event_charities
              currentCharities.value = await charitiesStore.getCharities(params);
            }

            let voteParams = new URLSearchParams();
            voteParams.set('query[voting_event_id_eq]', currentVotingEvent.value.id);
            voteParams.set('query[user_id_eq]', document.body.dataset.currentUserId);
            voteParams.set('query[archived_eq]', 'false');

            currentVote.value = await votesStore.getVotes(voteParams, true);
            await getTeamVoting();
          } else if (!currentVotingEvent.value && currentMember.value) {
            // No event is scheduled, but user already has membership
            router.go(-1);
          } else {
            // User isn't a member, create membership
            await createMembership();
          }
        } else {
          // Display some sort of error
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getTeamVoting = async () => {
      // Check if current member belongs to a team
      if (currentMember.value && currentMember.value.team) {
        let params = {
          team_id: currentMember.value.team.id,
        };

        teamVote.value = await votingEventsStore.getVotingEventTeamVote(currentVotingEvent.value.id, params);
      }

      isLoading.value = false;
    };

    const createMembership = async () => {
      new Promise((resolve, reject) => {
        let data = {
          memberable_type: "Chapter",
          memberable_id: currentChapter.value.id,
          user_id: document.body.dataset.currentUserId
        };

        membersStore.newMember(data)
          .then(async () => {
            resolve();
            await getVotingActivity();
          })
          .catch(error => {
            reject(error);
          });
      });
    };

    watch(() => modalStore.positiveHandleTrigger, (value) => {
      if (value) {
        new Promise((resolve, reject) => {
          let currentVotingCharity = currentVotingEventCharities.value.find((vec) => { return vec.charity_id === votingStore.charitySelection.id; });
          let data = {
            voting_event_charity_id: currentVotingCharity.id,
            voting_event_id: currentVotingEvent.value.id,
            user_id: document.body.dataset.currentUserId
          };

          votesStore.newVote(data)
            .then(() => {
              modalStore.resetModal();
              resolve();
            })
            .catch(error => {
              reject(error);
            });
        })
        .then(async () => {
          await getVotingActivity();
        });
      }
    });

    return {
      props,
      chaptersStore,
      votingEventsStore,
      membersStore,
      votingStore,
      modalStore,
      isLoading,
      t,
      currentChapter,
      currentMember,
      currentVotingEvent,
      currentVotingEventCharities,
      currentCharities,
      currentVote,
      isEventLive,
      teamVote,
      getMemberForChapter,
      getVotingEventForChapter,
    };
  }
};

</script>

<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex items-center justify-center justify-between mb-2">
        <router-link to="/dashboard">
          <div class="flex justify-start items-center back-button" @click="votingStore.setChapterSelection(null)">
            <img src="@/assets/icons/arrow_back_ios_new_FILL0_wght400_GRAD0_opsz24.svg" class="mr-1" alt="Back Icon" />
            {{ t('voting.back') }}
          </div>
        </router-link>
        <router-link :to="'/voting/' + currentChapter.unique_path + '/results'" v-if="currentMember.status === 'Approved' && currentMember.role === 'Organizer'">
          <div class="flex justify-start items-center back-button">
            {{ t('voting.results') }}
            <img src="@/assets/icons/arrow_back_ios_new_FILL0_wght400_GRAD0_opsz24.svg" class="ml-1 rotate-180" alt="Back Icon" />
          </div>
        </router-link>
      </div>
      <p v-if="currentMember.status !== 'Denied' && currentVote.length === 0 && !teamVote" class="font-weight-500 text-primary-color text-md dark:text-neutral-50 mb-2">{{ t('voting.voting_details') }}</p>
      <p v-else-if="currentMember.team && teamVote" class="font-weight-500 text-primary-color text-md dark:text-neutral-50 mb-2">{{ t('voting.voting_details_voted_team', { user_name: `${teamVote.user.first_name} ${teamVote.user.last_name}` }) }}</p>
      <p v-else-if="currentMember.status === 'Denied'" class="font-weight-500 text-primary-color text-md dark:text-neutral-50 mb-2">{{ t('voting.voting_details_denied') }}</p>
      <p v-else-if="currentVote.length > 0" class="font-weight-500 text-primary-color text-md dark:text-neutral-50 mb-2">{{ t('voting.voting_details_voted') }}</p>

      <!-- Team notice -->
      <div v-if="currentMember.team && !teamVote" class="bg-orange-200 border border-orange-500 border-4 text-gray-800 px-4 py-2 rounded-lg my-2">
        <p class="text-xs">{{ t('voting.voting_details_team', { team: currentMember.team.name }) }}</p>
      </div>

      <h2 class="title-h2 text-primary-color mb-2">{{ t('voting.charities') }}</h2>
      <div id="accordion-wrapper">
        <CharityAccordion
          v-for="(charity, index) in currentCharities"
          :key="index"
          :accordion-index="index"
          :charity="charity"
          :voting-event="currentVotingEvent"
          :member="currentMember"
          :current-vote="currentVote"
          :team-vote="teamVote"
        />
        <div v-if="currentMember.status !== 'Denied' && currentVote.length === 0 && isEventLive(currentVotingEvent.start_time, currentVotingEvent.end_time) && !teamVote && currentCharities" class="flex justify-center mt-25">
          <button type="button" :disabled="votingStore.charitySelection === null" :class="`${votingStore.charitySelection !== null ? 'primary-button' : 'secondary-button'} full-rounded full-padding`" @click="modalStore.setModal('confirm_vote', votingStore.charitySelection.name)">
            {{ t('voting.confirm_vote') }}
          </button>
        </div>
        <div v-if="!currentCharities" class="event-container">
          <div class="text-container">
            <div class="flex justify-between items-center">
              <h5 class="font-weight-600 leading-tight text-primary-color dark:text-neutral-50 text-lg mb-1">
                {{ t('voting.no_charites') }}
              </h5>
            </div>
            <p class="mb-1 text-sm font-weight-500 leading-tight text-primary-color dark:text-neutral-50">
              {{ t('voting.no_charities_text') }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="fixed inset-0 flex items-center justify-center">
        <div class="inline-block h-16 w-16 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
