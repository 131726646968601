<script>
// Stores
import { useI18n } from "vue-i18n";
import {computed, onMounted, watch, ref} from "vue";
import { debounce } from 'lodash';
import { formatDate, formatTimeRange } from "@/javascript/lib/date_helper.js";
import { useModalStore } from '@/javascript/stores/modal.js';
import { useTeamsStore } from '@/javascript/stores/teams.js';
import { useUsersStore } from '@/javascript/stores/users.js';
import { useAuthenticationStore } from '@/javascript/stores/authentication.js';

export default {
  setup(props) {
    // I18n
    const { t } = useI18n();
    const base64Img = ref(null);

    // Stores
    const modalStore = useModalStore();
    const teamsStore = useTeamsStore();
    const usersStore = useUsersStore();
    const authenticationStore = useAuthenticationStore();

    onMounted(async () => {});

    const positiveButtonDisabled = computed(() => {
     switch (modalStore.modalType) {
        case 'new_member':
         return (modalStore.newMember.first_name === '' || modalStore.newMember.first_name === null) ||
           (modalStore.newMember.last_name === '' || modalStore.newMember.last_name === null) ||
           (modalStore.newMember.email === '' || modalStore.newMember.email === null) ||
           modalStore.newMember.role === t('modal.new_member.form.select_role');
        case 'manual_vote':
          return (modalStore.manualVote.name === '' || modalStore.manualVote.name === null) ||
            modalStore.manualVote.voting_event_charity_id === t('modal.manual_vote.select_charity');
        default:
          return false;
      }
    });

    const isChecked = (charity) => {
      return !!modalStore.modalTypeExtraParam.selectedCharities.find(c => c === charity.id);
    };

    const setSelectedCharity = (charity) => {
      if (isChecked(charity)) {
        const index = modalStore.modalTypeExtraParam.selectedCharities.findIndex(c => c === charity.id);
        modalStore.modalTypeExtraParam.selectedCharities.splice(index, 1);
      } else {
        modalStore.modalTypeExtraParam.selectedCharities.push(charity.id);
      }
    };

    const debouncedUserSearch = debounce(async (email) => {
      if (email && email.length > 0) {
        let params = new URLSearchParams();
        params.set('query[email_eq]', email.toLowerCase());
        params.set('query[archived_eq]', false);

        let users = await usersStore.getUsers(params);
        if (users && users.length > 0) {
          modalStore.setExistingUser(users[0]);
          modalStore.setNewMember({
            first_name: modalStore.existingUser.first_name,
            last_name: modalStore.existingUser.last_name,
            email: modalStore.existingUser.email,
            employer_name: modalStore.existingUser.employer_name,
            address: modalStore.existingUser.address,
            city: modalStore.existingUser.city,
            postal_code: modalStore.existingUser.postal_code,
            phone_number: modalStore.existingUser.phone_number,
            role: t('modal.new_member.form.select_role'),
            team_id: t('modal.new_member.form.select_team'),
          });
        } else {
          modalStore.setExistingUser(null);
        }
      } else {
        modalStore.setExistingUser(null);
      }
    }, 300);

    const onFileChange = (event) => {
      modalStore.setImage(event.target.files[0]);
      encodeImage(modalStore.image);
    };

    const encodeImage = (input) => {
      if (input) {
        const reader = new FileReader();
        reader.onload = (e) => {
          base64Img.value = e.target.result;
        };
        reader.readAsDataURL(input);
      }
    };

    watch(() => modalStore.newMember.email, (email) => {
      if (modalStore.modalType === 'new_member') {
        debouncedUserSearch(email);
      } else {
        modalStore.setExistingUser(null);
      }
    });

    return {
      t,
      props,
      modalStore,
      teamsStore,
      positiveButtonDisabled,
      formatDate,
      formatTimeRange,
      base64Img,
      isChecked,
      setSelectedCharity,
      onFileChange,
      encodeImage,
      authenticationStore,
    };
  }
};
</script>

<template>
  <div
    v-if="modalStore.modalVisibility"
    data-te-modal-init
    class="fixed inset-0 flex items-center justify-center w-full items-center z-10"
    id="exampleModalCenter"
    tabindex="-1"
    aria-labelledby="exampleModalCenterTitle"
    aria-modal="true"
    role="dialog"
    :class="{ 'fade-in': modalStore.modalVisibility }">
    <div class="z-20 fixed inset-0 bg-black opacity-50 overflow-auto"></div>
    <div
      data-te-modal-init
      class="relative w-full z-30 fixed inset-0 flex items-center justify-center opacity-100 transition-all duration-300 ease-in-out min-[300px]:mx-auto sm:max-w-[95%] md:max-w-[75%] lg:max-w-[50%] xl:max-w-[40%]">
      <div
        data-te-modal-dialog-ref
        class="pointer-events-none relative w-full opacity-100 transition-all duration-300 ease-in-out min-[300px]:mx-auto min-[300px]:mx-auto max-w-[94%]">
        <div
          class="min-[300px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none dark:bg-neutral-600">
          <div
            class="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 py-3 px-4 dark:border-opacity-50 modal-header">
            <!--Modal title-->
            <h5
              class="text-xl font-semibold leading-normal text-neutral-800 dark:text-neutral-200"
              id="exampleModalLabel">
              {{ modalStore.modalTitle }}
            </h5>
            <!--Close button-->
            <button
              type="button"
              class="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
              data-te-modal-dismiss
              aria-label="Close"
              @click="modalStore.negativeHandler"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="h-6 w-6">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <!--Modal body-->
          <div v-if="modalStore.modalBody" class="relative flex-auto text-md p-4" data-te-modal-body-ref v-html="modalStore.modalBody"></div>

          <div v-if="modalStore.modalErrors && modalStore.modalErrors.length > 0" class="bg-red-100 mx-4 mb-2 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
            <ul class="list-disc ml-5 text-sm">
              <li v-for="(error, index) in modalStore.modalErrors" :key="index">{{ error }}</li>
            </ul>
          </div>

          <!--Manual vote section-->
          <template v-if="modalStore.modalType === 'manual_vote'">
            <div class="flex flex-col space-y-4 px-4 pb-4">
              <input type="text" :placeholder="`${t('modal.manual_vote.name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.manualVote.name">
              <div class="relative w-full">
                <select class="w-full border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.manualVote.voting_event_charity_id">
                  <option :value="t('modal.manual_vote.select_charity')" disabled selected>{{ t('modal.manual_vote.select_charity') }}</option>
                  <option v-for="(charity) in modalStore.modalTypeExtraParam" :value="charity.id" :key="charity.id">{{ charity.name }}</option>
                </select>
              </div>
            </div>
          </template>

          <!--Approved votes sections-->
          <template v-if="modalStore.modalType === 'approved_votes'">
            <div class="max-h-[250px] overflow-y-auto">
              <div v-for="(member) in modalStore.modalTypeExtraParam.members" :key="member.id" class="flex flex-col space-y-2 px-4 pb-2">
                <div class="flex items-center justify-center justify-between">
                  <span class="font-weight-500" v-if="member.user">{{ member.user.first_name }} {{ member.user.last_name }}</span>
                  <span class="font-weight-500" v-else>{{ member.name }}</span>
                  <div :class="`${member.role === t('voting_results.manual_vote') ? 'pending' : 'voted'} pill`">
                    <span>{{ member.role }}</span>
                  </div>
                </div>
                <hr class="border-t border-gray-300">
              </div>
            </div>
          </template>

          <!--Approved votes sections-->
          <template v-if="modalStore.modalType === 'event_details'">
            <div class="max-h-[250px] overflow-y-auto">
              <div class="flex flex-col px-4 py-2">
                <div class="flex justify-start mb-2">
                  <p><span class="font-weight-600 mr-1">{{ t('modal.event_details.address') }}:</span><span>{{ modalStore.modalTypeExtraParam.address }}, {{ modalStore.modalTypeExtraParam.city }}, {{ modalStore.modalTypeExtraParam.state }}</span></p>
                </div>
                <div class="flex justify-start mb-2">
                  <p><span class="font-weight-600 mr-1">{{ t('modal.event_details.date') }}:</span><span>{{ formatDate(modalStore.modalTypeExtraParam.start_time) }}</span></p>
                </div>
                <div class="flex justify-start">
                  <p><span class="font-weight-600 mr-1">{{ t('modal.event_details.time') }}:</span><span>{{ formatTimeRange(modalStore.modalTypeExtraParam.start_time, modalStore.modalTypeExtraParam.end_time) }}</span></p>
                </div>
                <div class="flex justify-start mt-2" v-if="modalStore.modalTypeExtraParam.description">
                  <p><span class="font-weight-600 mr-1">{{ t('modal.event_details.additional_info') }}:</span><span>{{ modalStore.modalTypeExtraParam.description }}</span></p>
                </div>
              </div>
            </div>
          </template>

          <!--New Member section-->
          <template v-if="modalStore.modalType === 'new_member' || modalStore.modalType === 'edit_member'">
            <div class="flex flex-col space-y-3 px-4 pb-4 max-h-[350px] overflow-y-auto">
              <div v-if="modalStore.existingUser" class="bg-orange-200 border border-orange-500 border-4 text-gray-800 px-4 py-2 rounded-lg my-2">
                <p class="font-bold text-sm mb-1">{{ t('modal.new_member.user_found') }}</p>
                <p class="text-xs">{{ t('modal.new_member.user_found_text', { email: modalStore.existingUser.email }) }}</p>
              </div>
              <div class="flex flex-col">
                <label for="email" class="font-medium">{{ t('modal.new_member.form.email_placeholder') }}</label>
                <input id="email" type="email" :placeholder="`${t('modal.new_member.form.email_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.email">
              </div>
              <div class="flex flex-col">
                <label for="first_name" class="font-medium">{{ t('modal.new_member.form.first_name_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="first_name" type="text" :placeholder="`${t('modal.new_member.form.first_name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.first_name">
              </div>
              <div class="flex flex-col">
                <label for="last_name" class="font-medium">{{ t('modal.new_member.form.last_name_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="last_name" type="text" :placeholder="`${t('modal.new_member.form.last_name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.last_name">
              </div>
              <div class="flex flex-col">
                <label for="employer_name" class="font-medium">{{ t('modal.new_member.form.employer_name_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="employer_name" type="text" :placeholder="`${t('modal.new_member.form.employer_name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.employer_name">
              </div>
              <div class="flex flex-col">
                <label for="address" class="font-medium">{{ t('modal.new_member.form.address_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="address" type="text" :placeholder="`${t('modal.new_member.form.address_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.address">
              </div>
              <div class="flex flex-col">
                <label for="city" class="font-medium">{{ t('modal.new_member.form.city_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="city" type="text" :placeholder="`${t('modal.new_member.form.city_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.city">
              </div>
              <div class="flex flex-col">
                <label for="postal_code" class="font-medium">{{ t('modal.new_member.form.postal_code_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="postal_code" type="text" :placeholder="`${t('modal.new_member.form.postal_code_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.postal_code">
              </div>
              <div class="flex flex-col">
                <label for="phone_number" class="font-medium">{{ t('modal.new_member.form.phone_number_placeholder') }}</label>
                <input :disabled="modalStore.existingUser" id="phone_number" type="text" :placeholder="`${t('modal.new_member.form.phone_number_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.phone_number">
              </div>
              <div class="flex flex-col">
                <label for="role" class="font-medium">{{ t('modal.new_member.form.role_placeholder') }}</label>
                <div class="relative w-full">
                  <select id="role" class="w-full border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.role">
                    <option :value="t('modal.new_member.form.select_role')" disabled selected>{{ t('modal.new_member.form.select_role') }}</option>
                    <option value="Voter">{{ t('modal.new_member.form.voter_role') }}</option>
                    <option value="Organizer">{{ t('modal.new_member.form.organizer_role') }}</option>
                  </select>
                </div>
              </div>
              <div class="flex flex-col">
                <label for="team" class="font-medium">{{ t('modal.new_member.form.team_placeholder') }}</label>
                <div class="relative w-full">
                  <select id="team" class="w-full border border-gray-300 rounded-md px-2 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newMember.team_id">
                    <option :value="t('modal.new_member.form.select_team')" selected>{{ t('modal.new_member.form.select_team') }}</option>
                    <option v-for="(team) in teamsStore.teams" :value="team.id" :key="team.id">{{ team.name }}</option>
                  </select>
                </div>
              </div>
            </div>
          </template>

          <!--New Event section-->
          <template v-if="modalStore.modalType === 'new_event' || modalStore.modalType === 'edit_event'">
            <div class="flex flex-col space-y-3 px-4 pb-4 max-h-[350px] overflow-y-auto">
              <div class="flex flex-col">
                <label for="start_time" class="font-medium">{{ t('modal.new_event.form.start_date_placeholder') }}</label>
                <input id="start_time" type="datetime-local" :placeholder="`${t('modal.new_event.form.start_date_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newEvent.start_time">
              </div>
              <div class="flex flex-col">
                <label for="end_time" class="font-medium">{{ t('modal.new_event.form.end_date_placeholder') }}</label>
                <input id="end_time" type="datetime-local" :placeholder="`${t('modal.new_event.form.end_date_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newEvent.end_time">
              </div>
              <div class="flex flex-col">
                <label for="address" class="font-medium">{{ t('modal.new_event.form.address_placeholder') }}</label>
                <input id="address" type="text" :placeholder="`${t('modal.new_event.form.address_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newEvent.address">
              </div>
              <div class="flex flex-col">
                <label for="city" class="font-medium">{{ t('modal.new_event.form.city_placeholder') }}</label>
                <input id="city" type="text" :placeholder="`${t('modal.new_event.form.city_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newEvent.city">
              </div>
              <div class="flex flex-col">
                <label for="postal_code" class="font-medium">{{ t('modal.new_event.form.postal_code_placeholder') }}</label>
                <input id="postal_code" type="text" :placeholder="`${t('modal.new_event.form.postal_code_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newEvent.postal_code">
              </div>
              <div class="flex flex-col">
                <label for="description" class="font-medium">{{ t('modal.new_event.form.description_label') }}</label>
                <textarea id="description" type="text" :style="{ minHeight: '100px' }" :placeholder="`${t('modal.new_event.form.description_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newEvent.description"></textarea>
              </div>
              <div class="flex flex-col">
                <p class="font-medium">{{ t('manage.chapter.events_section.charities') }}</p>
                <div v-for="(charity, index) in modalStore.modalTypeExtraParam.currentCharities" :key="'charity-' + index" class="flex justify-between items-center px-1 space-y-3 border-t border-gray-300">
                  <input
                    :checked="isChecked(charity)"
                    class="charity-checkbox small-version appearance-none rounded-[0.25rem] border-[0.125rem] border-solid outline-none before:pointer-events-none before:absolute before:h-[0.500rem] before:w-[0.500rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:-mt-px checked:after:ml-[0.25rem] checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)]"
                    type="checkbox"
                    :value="false"
                    @change="setSelectedCharity(charity)"
                  />
                  <p class="text-sm pb-3">{{ charity.name }}</p>
                </div>
              </div>
            </div>
          </template>

          <!--New Charity section-->
          <template v-if="modalStore.modalType === 'new_charity' || modalStore.modalType === 'edit_charity'">
            <div class="flex flex-col space-y-3 px-4 pb-4 max-h-[350px] overflow-y-auto">
              <div class="flex flex-col">
                <label for="name" class="font-medium">{{ t('modal.new_charity.form.name_placeholder') }}</label>
                <input id="name" type="text" :placeholder="`${t('modal.new_charity.form.name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newCharity.name">
              </div>
              <div class="flex flex-col">
                <label for="address" class="font-medium">{{ t('modal.new_charity.form.address_placeholder') }}</label>
                <input id="address" type="text" :placeholder="`${t('modal.new_charity.form.address_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newCharity.address">
              </div>
              <div class="flex flex-col">
                <label for="city" class="font-medium">{{ t('modal.new_charity.form.city_placeholder') }}</label>
                <input id="city" type="text" :placeholder="`${t('modal.new_charity.form.city_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newCharity.city">
              </div>
              <div class="flex flex-col">
                <label for="description" class="font-medium">{{ t('modal.new_charity.form.description_label') }}</label>
                <textarea id="description" type="text" :style="{ minHeight: '150px' }" :placeholder="`${t('modal.new_charity.form.description_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newCharity.description"></textarea>
              </div>
              <div class="flex flex-col">
                <label for="video_url" class="font-medium">{{ t('modal.new_charity.form.video_url_label') }}</label>
                <input id="video_url" type="text" :placeholder="`${t('modal.new_charity.form.video_url_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newCharity.video_url">
              </div>
              <div class="flex flex-col">
                <label for="image" class="font-medium">{{ t('modal.new_charity.form.image_label') }}</label>
                <label for="image" class="cursor-pointer flex items-center justify-center rounded-md px-4 py-6 upload-container">
                  <span class="font-medium">{{ t('modal.new_charity.form.image_placeholder') }}</span>
                  <input id="image" type="file" class="sr-only" @change="onFileChange">
                </label>
              </div>
              <div v-if="modalStore.image" class="flex justify-center">
                <img :src="base64Img" class="object-scale-down w-2/3 h-auto">
              </div>
            </div>
          </template>

          <!--New Team section-->
          <template v-if="modalStore.modalType === 'new_team' || modalStore.modalType === 'edit_team'">
            <div class="flex flex-col space-y-3 px-4 pb-4 max-h-[350px] overflow-y-auto">
              <div class="flex flex-col">
                <label for="name" class="font-medium">{{ t('modal.new_team.form.name_placeholder') }}</label>
                <input id="name" type="text" :placeholder="`${t('modal.new_team.form.name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.newTeam.name">
              </div>
            </div>
          </template>

          <!--Edit Profile section -->
          <template v-if="modalStore.modalType === 'edit_profile'">
            <div class="flex flex-col pt-2 space-y-3 px-4 pb-4 max-h-[350px] overflow-y-auto">
              <div class="flex flex-col">
                <label for="image" class="font-medium">{{ t('modal.edit_profile.image_label') }}</label>
                <label for="image" class="cursor-pointer flex items-center justify-center rounded-md px-4 py-6 upload-container">
                  <span class="font-medium">{{ t('modal.edit_profile.image_placeholder') }}</span>
                  <input id="image" type="file" class="sr-only" @change="onFileChange">
                </label>
              </div>
              <div v-if="modalStore.image" class="flex justify-center">
                <img :src="base64Img" class="object-scale-down w-2/3 h-auto">
              </div>
              <div class="bg-orange-200 border border-orange-500 border-4 text-gray-800 px-4 py-2 rounded-lg my-2">
                <p class="font-bold text-sm mb-1">{{ t('modal.edit_profile.notice') }}</p>
                <p class="text-xs">{{ t('modal.edit_profile.disclaimer') }}</p>
              </div>
              <div class="flex flex-col">
                <label for="email" class="font-medium">{{ t('modal.new_member.form.email_placeholder') }}</label>
                <input id="email" type="email" disabled :placeholder="`${t('modal.new_member.form.email_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.email">
              </div>
              <div class="flex flex-col">
                <label for="first_name" class="font-medium">{{ t('modal.new_member.form.first_name_placeholder') }}</label>
                <input id="first_name" type="text" disabled :placeholder="`${t('modal.new_member.form.first_name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.first_name">
              </div>
              <div class="flex flex-col">
                <label for="last_name" class="font-medium">{{ t('modal.new_member.form.last_name_placeholder') }}</label>
                <input id="last_name" type="text" disabled :placeholder="`${t('modal.new_member.form.last_name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.last_name">
              </div>
              <div class="flex flex-col">
                <label for="employer_name" class="font-medium">{{ t('modal.new_member.form.employer_name_placeholder') }}</label>
                <input id="employer_name" type="text" disabled :placeholder="`${t('modal.new_member.form.employer_name_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.employer_name">
              </div>
              <div class="flex flex-col">
                <label for="address" class="font-medium">{{ t('modal.new_member.form.address_placeholder') }}</label>
                <input id="address" type="text" disabled :placeholder="`${t('modal.new_member.form.address_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.address">
              </div>
              <div class="flex flex-col">
                <label for="city" class="font-medium">{{ t('modal.new_member.form.city_placeholder') }}</label>
                <input id="city" type="text" disabled :placeholder="`${t('modal.new_member.form.city_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.city">
              </div>
              <div class="flex flex-col">
                <label for="postal_code" class="font-medium">{{ t('modal.new_member.form.postal_code_placeholder') }}</label>
                <input id="postal_code" disabled type="text" :placeholder="`${t('modal.new_member.form.postal_code_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.postal_code">
              </div>
              <div class="flex flex-col">
                <label for="phone_number" class="font-medium">{{ t('modal.new_member.form.phone_number_placeholder') }}</label>
                <input id="phone_number" disabled type="text" :placeholder="`${t('modal.new_member.form.phone_number_placeholder')}...`" class="border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500" v-model="modalStore.editProfile.phone_number">
              </div>
            </div>
          </template>

          <!--Modal footer-->
          <div
            class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
            <button
              v-if="modalStore.negativeButtonText"
              type="button"
              class="inline-block secondary-button"
              data-te-modal-dismiss
              @click="modalStore.negativeHandler">
              {{ modalStore.negativeButtonText }}
            </button>
            <button
              v-if="modalStore.positiveButtonText"
              type="button"
              class="inline-block primary-button ml-5"
              :disabled="positiveButtonDisabled"
              @click="modalStore.setPositiveHandleTrigger(true)">
              {{ modalStore.positiveButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>
